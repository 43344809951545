import * as React from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  useTheme,
  Tooltip,
  adaptV4Theme,
  SxProps,
  Theme,
} from '@mui/material';
import { AllIconsType } from '../index';
import deepmerge from 'deepmerge';
import Icon from '../Icon';
import { darkThemeStyles } from '../Theme';
import { designTokens } from '../Theme.design-tokens';

type Variant = MuiButtonProps['variant'] | 'tertiary';

export interface ButtonProps
  extends Partial<
    Pick<
      MuiButtonProps,
      'disabled' | 'fullWidth' | 'href' | 'type' | 'disableRipple'
    >
  > {
  /**
   * If button invokes a dropdown, set to id of dropdown DOM node.
   */
  a11yControls?: MuiButtonProps['aria-controls'];
  /**
   * If button invokes a popover, set to id of popover DOM node.
   */
  a11yDescribedby?: MuiButtonProps['aria-describedby'];
  /**
   * if button invokes a dropdown, set to 'true'.
   */
  a11yHaspopup?: MuiButtonProps['aria-haspopup'];
  /**
   * Accessibility label (needed for Icon Button only)
   */
  a11yLabel?: string;
  buttonRef?: MuiButtonProps['ref'];
  body?: React.ReactNode;
  children?: React.ReactNode;
  dsOnClick?: MuiIconButtonProps['onClick'] | MuiButtonProps['onClick'];
  e2e?: string;
  startIcon?: AllIconsType;
  endIcon?: AllIconsType;
  icon?: AllIconsType;
  size?: 'small' | 'medium';
  tooltip?: string;
  disableInteractiveTooltip?: boolean;
  variant?: Variant | string;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
  target?: string;
}

const darkContainedPrimaryTheme = createTheme(
  adaptV4Theme(
    deepmerge(darkThemeStyles, {
      palette: {
        primary: {
          main: designTokens.colors.blue500,
        },
      },
    }),
  ),
);

const darkOutlinedPrimaryTheme = createTheme(
  adaptV4Theme(
    deepmerge(darkThemeStyles, {
      palette: {
        primary: {
          main: designTokens.colors.white,
        },
      },
    }),
  ),
);

const Button: React.FC<ButtonProps> = ({
  a11yControls,
  a11yDescribedby,
  a11yHaspopup,
  a11yLabel,
  body,
  buttonRef,
  children,
  dsOnClick,
  e2e,
  fullWidth,
  disabled,
  disableRipple,
  href,
  startIcon,
  endIcon,
  icon,
  size,
  tooltip = '',
  disableInteractiveTooltip = false,
  type,
  variant = 'contained',
  autoFocus = false,
  sx,
  target,
}) => {
  const theme = useTheme();

  const defaultButton = () => {
    let buttonVariant;
    let className;

    switch (variant) {
      case 'tertiary':
        className = 'tertiary';
        buttonVariant = 'outlined';
        break;
      default:
        buttonVariant = variant;
    }

    return (
      <MuiButton
        aria-controls={a11yControls}
        aria-describedby={a11yDescribedby || body || tooltip}
        aria-haspopup={a11yHaspopup}
        variant={buttonVariant}
        color="primary"
        {...(className && { className: className })}
        disabled={disabled}
        disableRipple={disableRipple}
        {...(e2e && { 'data-e2e': e2e })}
        fullWidth={fullWidth}
        onClick={dsOnClick}
        href={href}
        size={size}
        type={type}
        focusRipple
        startIcon={startIcon && <Icon color="inherit" body={startIcon} />}
        endIcon={endIcon && <Icon color="inherit" body={endIcon} />}
        ref={buttonRef}
        autoFocus={autoFocus}
        target={target}
        sx={sx}
      >
        <span {...(e2e && { 'data-e2e': `${e2e}-body` })}>
          {body}
          {children}
        </span>
      </MuiButton>
    );
  };

  const iconButton = (
    <MuiIconButton
      aria-label={a11yLabel || tooltip || icon}
      sx={sx}
      disabled={disabled}
      disableRipple={disableRipple}
      {...(e2e && { 'data-e2e': e2e })}
      onClick={dsOnClick as any}
      {...(href && { href })}
      size={size}
      ref={buttonRef}
      autoFocus={autoFocus}
    >
      <Icon color="inherit" body={icon as any} />
    </MuiIconButton>
  );

  const button = icon ? iconButton : defaultButton();
  const wrappedButton = tooltip ? (
    <Tooltip
      arrow
      title={tooltip}
      PopperProps={{
        ...(e2e && { 'data-e2e': `${e2e}-tooltip` }),
        modifiers: [],
      }}
      disableInteractive={disableInteractiveTooltip}
    >
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );

  const isDark = theme.palette.mode === 'dark';
  if (isDark && !disabled) {
    switch (variant) {
      case 'contained': {
        return (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={darkContainedPrimaryTheme}>
              {wrappedButton}
            </ThemeProvider>
          </StyledEngineProvider>
        );
      }
      default: {
        return (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={darkOutlinedPrimaryTheme}>
              {wrappedButton}
            </ThemeProvider>
          </StyledEngineProvider>
        );
      }
    }
  }

  return wrappedButton;
};

Button.defaultProps = {
  variant: 'contained',
};

Button.displayName = 'Button';

export default Button;

import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import { designTokens } from '../Theme.design-tokens';

const TitleBar = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: designTokens.colors.blue500,
        width: '2px',
        marginRight: `${theme.spacing(1)}`,
        marginTop: `${theme.spacing(0.5)}`,
        marginBottom: `${theme.spacing(0.5)}`,
      }}
    />
  );
};

export default TitleBar;

import * as React from 'react';
import { SnackbarOrigin, SnackbarProps } from '@mui/material';
import { AlertProps } from '../Alert';
import SingleToast from './SingleToast';
import MultipleToast from './MultipleToast';

export interface ToastProps
  extends Pick<AlertProps, 'severity' | 'header' | 'action' | 'variant'> {
  e2e?: string;
  message: React.ReactNode;
  keyRender?: number;
  closeable?: boolean;
  anchorOrigin?: SnackbarOrigin;
  duration?: SnackbarProps['autoHideDuration'];
  loading?: boolean;
}

export interface ToastControllerProps extends ToastProps {
  multiple?: boolean;
}

const ToastController: React.FC<ToastControllerProps> = ({
  multiple = false,
  ...props
}: ToastControllerProps) => {
  return (
    <>{multiple ? <MultipleToast {...props} /> : <SingleToast {...props} />}</>
  );
};

export default ToastController;

import * as React from 'react';
import { useEffect } from 'react';
import { ToastProps } from '.';
import Alert, { AlertProps } from '../Alert';
import { Box, Snackbar } from '@mui/material';

interface Message {
  message: React.ReactNode;
  header: React.ReactNode;
  severity: AlertProps['severity'];
  key: number;
}

const SingleToast: React.FC<ToastProps> = ({
  e2e,
  severity,
  message,
  header,
  keyRender,
  closeable = true,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  duration = null,
  variant = 'outlined',
  loading = false,
  action,
}: ToastProps) => {
  const queueRef = React.useRef<Message[]>([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<Message>();
  const [autoHideDuration, setAutoHideDuration] = React.useState<number | null>(
    duration,
  );

  const processQueue = () => {
    if (queueRef.current.length > 0) {
      setMessageInfo(queueRef.current.shift());
      setOpen(true);
    }
  };

  useEffect(() => {
    setAutoHideDuration(duration);
  }, [duration]);

  useEffect(() => {
    if (!message) {
      return;
    }
    queueRef.current.push({
      message: message,
      header: header,
      severity: severity,
      key: keyRender || new Date().getTime(),
    });

    if (open) {
      // immediately begin dismissing current message
      // to start showing new one !
      setOpen(false);
    } else {
      processQueue();
    }
  }, [message, header, severity, keyRender]);

  const handleClose = (_: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    processQueue();
  };

  const handleHover = () => {
    setAutoHideDuration(null);
  };
  const handleHoverEnd = () => {
    setAutoHideDuration(duration);
  };

  return (
    <Snackbar
      {...(e2e && { 'data-e2e': e2e })}
      key={messageInfo?.key || undefined}
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverEnd}
      transitionDuration={100}
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <Box>
        <Alert
          {...(e2e && { e2e: `${e2e}-alert` })}
          severity={messageInfo?.severity || 'info'}
          dsOnClose={handleClose as any}
          open
          loading={loading}
          variant={variant}
          dismissible={closeable}
          body={messageInfo?.message}
          header={messageInfo?.header}
          action={action}
        />
      </Box>
    </Snackbar>
  );
};

export default SingleToast;

import { CreateStyled } from '@emotion/styled';
import {
  deDE as baseDeDE,
  enUS as baseEnUS,
  esES as baseEsES,
  frFR as baseFrFR,
  jaJP as baseJaJP,
  Localization,
} from '@mui/material/locale';
import { de, en, es, fr, ja, useTranslate } from './i18n';

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

/*
  legacy billing pages only regard locale for number formatting purposes.
  Since the locale will be used to set the language of MUI widgets, we
  need to infer it from the user's language.
 */
export const getLocaleBasedOnLanguage = (language?: string) => {
  const supportedLanguageLocales = {
    en: 'en-US',
    fr: 'fr-FR',
    es: 'es-ES',
    de: 'de-DE',
    ja: 'ja-JP',
  };
  let locale = supportedLanguageLocales.en;

  if (language && Object.keys(supportedLanguageLocales).includes(language)) {
    locale = supportedLanguageLocales[language];
  }
  return locale;
};

const formatMasks = {
  'dd/MM/yyyy': '__/__/____',
  'dd.MM.yyyy': '__.__.____',
  'dd.MM.yyyy.': '__.__.____.',
  'dd-MM-yyyy': '__-__-____',
  'd/M/yyyy': '_/_/____',
  'd. M. yyyy.': '', // mask doesn't work properly with spaces
  'dd. MM. yyyy': '', // mask doesn't work properly with spaces
  'd.MM.yyyy': '_.__.____',
  'MM/dd/yyyy': '__/__/____',
  'MM/d/yyyy': '__/_/____',
  'yyyy/MM/dd': '____/__/__',
  'yyyy-MM-dd': '____-__-__',
  'yyyy.MM.dd.': '____.__.__.',
};

export interface LocaleConfig {
  [zLocaleId: string]: LocaleData;
}

export interface LocaleData {
  muiLocaleMeta?: Localization;
  format: string;
  mask: string;
}

const initLocaleMetaFactory = (
  translate: (translationKey: string, replacements?: string[]) => string,
) => {
  const config = {
    en: { muiBaseLocale: baseEnUS, i18nKey: en },
    es: { muiBaseLocale: baseEsES, i18nKey: es },
    fr: { muiBaseLocale: baseFrFR, i18nKey: fr },
    de: { muiBaseLocale: baseDeDE, i18nKey: de },
    ja: { muiBaseLocale: baseJaJP, i18nKey: ja },
  };

  return (localeKey: string) => ({
    components: {
      ...config[localeKey].muiBaseLocale.components,
      MuiTablePagination: {
        ...config[localeKey].muiBaseLocale.components?.MuiTablePagination,
        defaultProps: {
          ...config[localeKey].muiBaseLocale.components?.MuiTablePagination
            ?.defaultProps,
          getItemAriaLabel: (type) => {
            if (type === 'first') {
              return translate(
                config[localeKey].i18nKey.TablePagination_GoToFirstPage,
              );
            }
            if (type === 'last') {
              return translate(
                config[localeKey].i18nKey.TablePagination_GoToLastPage,
              );
            }
            if (type === 'next') {
              return translate(
                config[localeKey].i18nKey.TablePagination_GoToNextPage,
              );
            }
            if (type === 'previous') {
              return translate(
                config[localeKey].i18nKey.TablePagination_GoToPreviousPage,
              );
            }
            return '';
          },
        },
      },
    },
  });
};

export const supportedLocales = [
  'ar-AE',
  'ar-BH',
  'ar-DZ',
  'ar-EG',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SD',
  'ar-SY',
  'ar-TN',
  'ar-YE',
  'be-BY',
  'bg-BG',
  'ca-ES',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-GR',
  'de-LU',
  'el-CY',
  'el-GR',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-MT',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-US',
  'en-ZA',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-CU',
  'es-DO',
  'es-EC',
  'es-ES',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PR',
  'es-PY',
  'es-SV',
  'es-US',
  'es-UY',
  'es-VE',
  'et-EE',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'ga-IE',
  'hi-IN',
  'hr-HR',
  'hu-HU',
  'in-ID',
  'is-IS',
  'it-CH',
  'it-IT',
  'iw-IL',
  'ja-JP',
  'ko-KR',
  'lt-LT',
  'lv-LV',
  'mk-MK',
  'ms-MY',
  'mt-MT',
  'nl-BE',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'sq-AL',
  'sr-BA',
  'sr-CS',
  'sr-ME',
  'sr-RS',
  'sv-SE',
  'th-TH',
  'tr-TR',
  'uk-UA',
  'vi-VN',
  'zh-CN',
  'zh-HK',
  'zh-SG',
  'zh-TW',
];

export const useLocaleConfig = () => {
  const { translate } = useTranslate();

  const localeMetaFactory = initLocaleMetaFactory(translate);

  const enUS: Localization = localeMetaFactory('en');
  const esES: Localization = localeMetaFactory('es');
  const deDE: Localization = localeMetaFactory('de');
  const frFR: Localization = localeMetaFactory('fr');
  const jaJP: Localization = localeMetaFactory('ja');

  const localeConfig: LocaleConfig = {
    'ar-AE': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-BH': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-DZ': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-EG': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-IQ': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-JO': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-KW': {
      format: 'dd/M/yyyy',
      mask: formatMasks['dd/M/yyyy'],
    },
    'ar-LB': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-LY': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-MA': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'ar-OM': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-QA': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-SA': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'ar-SD': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-SY': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'ar-TN': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'ar-YE': {
      format: 'd/M/yyyy',
      mask: formatMasks['d/M/yyyy'],
    },
    'be-BY': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'bg-BG': {
      format: 'd.MM.yyyy',
      mask: formatMasks['d.MM.yyyy'],
    },
    'ca-ES': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'cs-CZ': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'da-DK': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'de-AT': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'de-CH': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'de-DE': {
      muiLocaleMeta: deDE,
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'de-GR': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'de-LU': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'el-CY': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'el-GR': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'en-AU': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'en-CA': {
      format: 'yyyy-MM-dd',
      mask: formatMasks['yyyy-MM-dd'],
    },
    'en-GB': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'en-IE': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'en-IN': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['__/__/____'],
    },
    'en-MT': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'en-NZ': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'en-PH': {
      format: 'MM/d/yyyy',
      mask: formatMasks['MM/d/yyyy'],
    },
    'en-SG': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'en-US': {
      muiLocaleMeta: enUS,
      format: 'MM/dd/yyyy',
      mask: formatMasks['MM/dd/yyyy'],
    },
    'en-ZA': {
      format: 'MM/dd/yyyy',
      mask: formatMasks['MM/dd/yyyy'],
    },
    'es-AR': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-BO': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-CL': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-CO': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-CR': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-CU': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-DO': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-EC': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-ES': {
      muiLocaleMeta: esES,
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-GT': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-HN': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-MX': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-NI': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-PA': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-PE': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-PR': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-PY': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-SV': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-US': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-UY': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'es-VE': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'et-EE': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'fi-FI': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'fr-BE': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['__/__/____'],
    },
    'fr-CA': {
      format: 'yyyy-MM-dd',
      mask: formatMasks['yyyy-MM-dd'],
    },
    'fr-CH': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'fr-FR': {
      muiLocaleMeta: frFR,
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'fr-LU': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'ga-IE': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'hi-IN': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'hr-HR': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'hu-HU': {
      format: 'yyyy.MM.dd.',
      mask: formatMasks['yyyy.MM.dd.'],
    },
    'in-ID': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'is-IS': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'it-CH': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'it-IT': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'iw-IL': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'ja-JP': {
      muiLocaleMeta: jaJP,
      format: 'yyyy/MM/dd',
      mask: formatMasks['yyyy/MM/dd'],
    },
    'ko-KR': {
      format: 'yyyy.MM.dd.',
      mask: formatMasks['yyyy.MM.dd.'],
    },
    'lt-LT': {
      format: 'yyyy-MM-dd',
      mask: formatMasks['yyyy-MM-dd'],
    },
    'lv-LV': {
      format: 'dd.MM.yyyy.',
      mask: formatMasks['dd.MM.yyyy.'],
    },
    'mk-MK': {
      format: 'd.MM.yyyy',
      mask: formatMasks['d.MM.yyyy'],
    },
    'ms-MY': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'mt-MT': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'nl-BE': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'nl-NL': {
      format: 'dd-MM-yyyy',
      mask: formatMasks['dd-MM-yyyy'],
    },
    'no-NO': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'pl-PL': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'pt-BR': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'pt-PT': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'ro-RO': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'ru-RU': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'sk-SK': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'sl-SI': {
      format: 'dd. MM. yyyy',
      mask: formatMasks['dd. MM. yyyy'],
    },
    'sq-AL': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'sr-BA': {
      format: 'd. M. yyyy.',
      mask: formatMasks['d. M. yyyy.'],
    },
    'sr-CS': {
      format: 'd. M. yyyy.',
      mask: formatMasks['d. M. yyyy.'],
    },
    'sr-ME': {
      format: 'd. M. yyyy.',
      mask: formatMasks['d. M. yyyy.'],
    },
    'sr-RS': {
      format: 'd. M. yyyy.',
      mask: formatMasks['d. M. yyyy.'],
    },
    'sv-SE': {
      format: 'yyyy-MM-dd',
      mask: formatMasks['yyyy-MM-dd'],
    },
    'th-TH': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'tr-TR': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'uk-UA': {
      format: 'dd.MM.yyyy',
      mask: formatMasks['dd.MM.yyyy'],
    },
    'vi-VN': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'zh-CN': {
      format: 'yyyy/MM/dd',
      mask: formatMasks['yyyy/MM/dd'],
    },
    'zh-HK': {
      format: 'yyyy/MM/dd',
      mask: formatMasks['yyyy/MM/dd'],
    },
    'zh-SG': {
      format: 'dd/MM/yyyy',
      mask: formatMasks['dd/MM/yyyy'],
    },
    'zh-TW': {
      format: 'yyyy/MM/dd',
      mask: formatMasks['yyyy/MM/dd'],
    },
  };

  return localeConfig;
};

export const isSafari = window?.navigator?.vendor?.toString().includes('Apple');

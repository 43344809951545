import * as React from 'react';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';

export interface TabProps
  extends Pick<MuiTabProps, 'disabled' | 'label' | 'onChange' | 'value'> {
  /**
   * (Accessibility) ID of element that displays this tab's contents.
   * Ensure this element the role attribute set: `[role="tabpanel"]` .
   */
  'aria-controls': MuiTabProps['aria-controls'];
  /**
   * (Accessibility) This value should be supplied the tab content's
   * element as aria-labelledby="id".
   */
  id: MuiTabProps['id'];
  component?: string;
  e2e?: string;
}

const Tab: React.FC<TabProps> = ({ e2e, ...args }: TabProps) => (
  <MuiTab {...(e2e && { 'data-e2e': e2e })} {...args} />
);

Tab.displayName = 'Tab';

export default Tab;

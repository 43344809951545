import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import * as React from 'react';
import { Cancel, Done } from '@mui/icons-material';
import Icon, { IconProps } from '../Icon';
export interface ChipProps
  extends Pick<MuiChipProps, 'label' | 'variant' | 'size' | 'disabled'> {
  dsOnDelete?: MuiChipProps['onDelete'];
  e2e?: string;
  state: MuiChipProps['color'];
  icon?: IconProps['body'];
  dismissible?: boolean;
  completable?: boolean;
  uppercase?: boolean;
  dsOnClick?: MuiChipProps['onClick'];
}

export interface StyleProps {
  uppercase?: boolean;
  variant?: MuiChipProps['variant'];
  state: ChipProps['state'];
  dsOnClick?: MuiChipProps['onClick'];
}

const Chip: React.FC<ChipProps> = ({
  dsOnDelete,
  e2e,
  state = 'default',
  variant,
  label,
  disabled,
  dismissible,
  completable,
  icon,
  size = 'small',
  uppercase = true,
  dsOnClick,
}: ChipProps) => {
  const ResolvedDeleteIcon = completable
    ? Done
    : dismissible
    ? Cancel
    : React.Fragment;
  const showDeleteIcon = completable || dismissible;

  return (
    <MuiChip
      sx={{
        textTransform: uppercase ? 'uppercase' : 'none',
      }}
      {...(e2e && { 'data-e2e': e2e })}
      size={size}
      icon={icon && <Icon fontSize="small" body={icon} />}
      label={label}
      disabled={disabled}
      variant={variant}
      onDelete={dismissible || completable ? dsOnDelete : undefined}
      deleteIcon={
        <ResolvedDeleteIcon
          {...(showDeleteIcon &&
            e2e && { 'data-e2e': `${e2e}-complete-dismiss` })}
        />
      }
      onClick={dsOnClick}
      color={state}
    />
  );
};

export default Chip;

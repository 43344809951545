import React from 'react';

export const Logo = () => (
  <svg
    x="0px"
    y="0px"
    xmlSpace="preserve"
    height="20"
    width="88"
    focusable="false"
    viewBox="0 0 429 88"
    enableBackground="new 0 0 429 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      d="M399.543 64.9712L405.054 84.5495H428.984L404.764 2.46545H369.378L344.869 84.4045H368.218L373.584 64.8261H399.543V64.9712ZM386.636 18.4182L395.773 51.4839H377.5L386.636 18.4182Z"
      fill="white"
    />
    <path
      d="M76.2831 64.3906H41.9122L75.848 2.61005H3.19054V22.7685H36.8363L0 84.5491H76.2831V64.3906Z"
      fill="white"
    />
    <path
      d="M319.055 53.6591C322.826 53.514 326.596 52.4989 329.932 50.6135C333.558 48.5832 336.603 45.6827 338.634 41.912C340.809 37.7063 341.969 33.0655 341.824 28.2797C341.679 23.4939 340.374 18.9981 338.053 14.7924C335.443 10.7317 331.672 7.54115 327.322 5.5108C322.681 3.33543 317.17 2.17523 310.934 2.17523H264.961V84.5494H288.31V48.7282L311.804 84.8394H338.924L317.75 53.8041L319.055 53.6591ZM307.888 41.622H288.31V21.4635H307.888C310.499 21.4635 313.109 22.4787 314.995 24.364C316.88 26.2494 317.895 28.8598 317.895 31.4703C317.895 34.0807 316.88 36.6912 314.995 38.5765C313.109 40.4618 310.644 41.622 307.888 41.622Z"
      fill="white"
    />
    <path
      d="M211.592 0C186.502 0 166.779 19.1433 166.779 43.5075C166.779 67.8716 186.502 87.0149 211.592 87.0149C236.681 87.0149 256.259 67.8716 256.259 43.5075C256.404 19.1433 236.681 0 211.592 0ZM211.592 65.5512C207.241 65.5512 203.035 64.246 199.409 61.9256C195.784 59.4602 193.028 56.1246 191.288 52.0639C189.548 48.0032 189.113 43.6525 189.983 39.4468C190.853 35.241 192.883 31.3254 195.929 28.1348C198.974 25.0893 202.89 22.9139 207.241 22.1888C211.446 21.3187 215.942 21.7537 220.003 23.494C224.064 25.0893 227.399 27.9898 229.865 31.6154C232.33 35.241 233.635 39.4468 233.635 43.7975C233.635 49.5985 231.315 55.1095 227.254 59.3152C223.048 63.2309 217.393 65.5512 211.592 65.5512Z"
      fill="white"
    />
    <path
      d="M121.966 87.0145C131.538 87.0145 140.819 83.2439 147.636 76.4277C154.452 69.6115 158.222 60.475 158.222 50.9033V2.61005H134.728V53.2237C134.728 56.5593 133.423 59.8949 130.958 62.2153C128.492 64.6807 125.302 65.9859 121.966 65.9859C118.631 65.9859 115.295 64.6807 112.975 62.2153C110.654 59.7498 109.204 56.5593 109.204 53.2237V2.61005H85.71V50.9033C85.71 60.475 89.4806 69.6115 96.2968 76.4277C103.113 83.2439 112.25 87.0145 121.966 87.0145Z"
      fill="white"
    />
  </svg>
);

export default Logo;

// Captured 5/21/2020
// https://zeroheight.com/2b9d6d810/p/684428-design-tokens/t/594161

const fontFamily =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

export const designTokens = {
  colors: {
    /** @deprecated */
    aqua050: '#F6FAFC',
    /** @deprecated */
    aqua100: '#D7E8ED',
    /** @deprecated */
    aqua200: '#BAD5DD',
    /** @deprecated */
    aqua300: '#9EC1CA',
    /** @deprecated */
    aqua400: '#85ACB6',
    /** @deprecated */
    aqua500: '#6D95A0',
    /** @deprecated */
    aqua600: '#547B86',
    /** @deprecated */
    aqua700: '#3D606A',
    /** @deprecated */
    aqua800: '#28444B',
    /** @deprecated */
    aqua900: '#15272B',
    black: '#000000',
    blue010: '#F7FBFF',
    /** @deprecated */
    blue030: '#F3F6FC',
    /** @deprecated */
    blue040: '#EDF1FB',
    blue050: '#E4F1FF',
    /** @deprecated */
    blue070: '#e7f1ff',
    blue100: '#C6DCFF',
    /** @deprecated */
    blue150: '#D0DCF4',
    blue200: '#8FB2F6',
    blue300: '#5E8BE5',
    blue400: '#3269D4',
    blue500: '#0D4AC3',
    blue600: '#06389C',
    blue700: '#042A75',
    blue800: '#031C4E',
    blue900: '#020F27',
    blueGray010: '#F9FBFE',
    blueGray050: '#F1F5F8',
    blueGray100: '#DFE6EB',
    blueGray200: '#BEC9D1',
    blueGray300: '#A0ADB7',
    blueGray400: '#85929C',
    blueGray500: '#6C7982',
    /** @deprecated */
    blueGray630: '#455974',
    /** @deprecated */
    blueGray660: '#3E4F67',
    blueGray600: '#546068',
    blueGray700: '#3B434A',
    blueGray800: '#262C30',
    blueGray900: '#14181A',
    burgundy010: '#FFF7FD',
    burgundy050: '#FFE0F6',
    burgundy100: '#F6C7E9',
    burgundy200: '#DB9ECA',
    burgundy300: '#BF7CAD',
    burgundy400: '#A46191',
    burgundy500: '#894A77',
    burgundy600: '#6D385E',
    burgundy700: '#522846',
    burgundy800: '#371A2F',
    burgundy900: '#1B0D17',
    coolGray050: '#f6f7f8',
    coolGray100: '#d6d9db',
    coolGray200: '#b5babe',
    coolGray300: '#959ba0',
    coolGray400: '#767d82',
    coolGray500: '#575e63',
    coolGray600: '#464c51',
    coolGray700: '#353b3f',
    coolGray800: '#24292c',
    coolGray900: '#141719',
    darkContextHigh: 'rgba(255, 255, 255, 0.24)',
    darkContextLow: 'rgba(255, 255, 255, 0.08)',
    darkContextMedium: 'rgba(255, 255, 255, 0.16)',
    /** @deprecated */
    darkEmphasisExtraLow: 'rgba(255, 255, 255, 0.2)',
    /** @deprecated */
    darkEmphasisExtraMedium: 'rgba(255, 255, 255, 0.8)',
    darkEmphasisHigh: '#ffffff',
    darkEmphasisLow: 'rgba(255, 255, 255, 0.6)',
    darkEmphasisMedium: 'rgba(255, 255, 255, 0.8)',
    darkError: '#DB1F0E',
    darkGreen010: '#F3FEFC',
    darkGreen050: '#D5F9F3',
    darkGreen100: '#B8EFE6',
    darkGreen200: '#8FD4C9',
    darkGreen300: '#6EBAAD',
    darkGreen400: '#539F93',
    darkGreen500: '#3E8579',
    darkGreen600: '#2E6A60',
    darkGreen700: '#215048',
    darkGreen800: '#153530',
    darkGreen900: '#0A1B18',
    darkLink: '#8FB2F6',
    darkMainContainer: '#16212B',
    darkOk: '#6EB38D',
    darkPageBackground: '#0B1116',
    darkRed010: '#FCF5F9',
    darkRed050: '#F3D7E7',
    darkRed100: '#E6B1CF',
    darkRed200: '#CD70A5',
    darkRed300: '#B43E81',
    darkRed400: '#9B1C64',
    darkRed500: '#81074C',
    darkRed600: '#68003B',
    darkRed700: '#4E002C',
    darkRed800: '#34001D',
    darkRed900: '#1A000F',
    darkWarning: '#E0B642',
    /** @deprecated */
    gray050: '#f6f6f6',
    /** @deprecated */
    gray100: '#d4d4d5',
    /** @deprecated */
    gray200: '#b2b2b3',
    /** @deprecated */
    gray300: '#919191',
    /** @deprecated */
    gray400: '#6f6f70',
    /** @deprecated */
    gray500: '#4d4d4e',
    /** @deprecated */
    gray600: '#3e3e3f',
    /** @deprecated */
    gray700: '#303031',
    /** @deprecated */
    gray800: '#212122',
    /** @deprecated */
    gray900: '#121213',
    green010: '#F7FCF8',
    green050: '#E1F3E6',
    /** @deprecated */
    green070: '#edf6ed',
    green100: '#C6E6D0',
    green200: '#95CCAA',
    green300: '#6EB38D',
    green400: '#509A74',
    green500: '#39805F',
    green600: '#28674C',
    green700: '#1A4D39',
    green800: '#103426',
    green900: '#081A13',
    lightContextExtraHigh: 'rgba(0, 0, 0, 0.24)',
    lightContextHigh: 'rgba(0, 0, 0, 0.12)',
    lightContextLow: 'rgba(0, 0, 0, 0.04)',
    lightContextMedium: 'rgba(0, 0, 0, 0.08)',
    lightEmphasisHigh: 'rgba(0, 0, 0, 0.9)',
    lightEmphasisLow: 'rgba(0, 0, 0, 0.4)',
    lightEmphasisMedium: 'rgba(0, 0, 0, 0.6)',
    lightError: '#B60F00',
    lightLink: '#0D4AC3',
    lightMainContainer: '#ffffff',
    lightOk: '#39805F',
    lightPageBackground: '#f6f7f8',
    lightWarning: '#E0B642',
    lightSelection: 'rgba(222, 236, 255, 0.5)',
    linkDisabled: '#5E8BE5',
    lightActionHover: 'rgba(210, 222, 231, 0.25)',
    lightActionActive: 'rgba(94, 139, 229, 0.25)',
    lightActionFocus: 'rgba(94, 139, 229, 0.5)',
    lightActionSelected: 'rgba(222,236,255,0.5)',
    lightActionHoverSelected: '#E5EDF7',
    actionHighlight: '#B8EFE6',
    darkActionHover: 'rgba(198, 220, 255, 0.12)',
    darkActionActive: 'rgba(143, 178, 246, 0.25)',
    darkActionFocus: 'rgba(142, 178, 246, 0.5)',
    darkDividerLow: 'rgba(175,192,205, 0.12)',
    darkDividerMedium: 'rgba(175,192,205, 0.2)',
    darkDividerHigh: 'rgba(175,192,205, 0.28)',
    darkOutlineBorder: 'rgba(255, 255, 255, 0.28)',
    darkStandartInputLine: 'rgba(255, 255, 255, 0.46)',
    navy010: '#F8F9FB',
    navy050: '#E3EAEF',
    navy100: '#CBD8E1',
    navy200: '#A0B6C6',
    navy300: '#7C97AC',
    navy400: '#5F7B91',
    navy500: '#476176',
    navy600: '#33495C',
    navy700: '#223341',
    navy800: '#16212B',
    navy900: '#0B1116',
    orange010: '#FFFAF6',
    orange050: '#FDEBDB',
    /** @deprecated */
    orange070: '#ffede7',
    orange100: '#F4D3B8',
    orange200: '#DFA77A',
    orange300: '#C9824A',
    orange400: '#B36526',
    orange500: '#954C11',
    orange600: '#773805',
    orange700: '#5A2800',
    orange800: '#3C1B00',
    orange900: '#1E0D00',
    purple010: '#FFFCFF',
    purple050: '#F6ECFF',
    purple100: '#E1D1FF',
    purple200: '#B69BFE',
    purple300: '#916FEF',
    purple400: '#6F4DCD',
    purple500: '#5435AB',
    purple600: '#3F2489',
    purple700: '#2D1866',
    purple800: '#1D0F44',
    purple900: '#0E0722',
    red010: '#FFF9F7',
    red050: '#FFE9E5',
    /** @deprecated */
    red070: '#fbe7e7',
    red100: '#FFC5BE',
    red200: '#FF8073',
    red300: '#FF4635',
    red400: '#DB1F0E',
    red500: '#B60F00',
    red600: '#920C00',
    red700: '#6D0900',
    red800: '#490600',
    red900: '#240300',
    teal010: '#EAFFFE',
    teal050: '#B6FFF9',
    teal100: '#85FBED',
    teal200: '#3AE6D2',
    teal300: '#00D2B9',
    teal400: '#00B49F',
    teal500: '#009684',
    teal600: '#00786A',
    teal700: '#005A4F',
    teal800: '#003C35',
    teal900: '#001E1A',
    /** @deprecated */
    white: '#ffffff',
    yellow010: '#FFFEF3',
    yellow050: '#FFFAD6',
    yellow100: '#FFEDB2',
    yellow200: '#F2CF74',
    yellow300: '#E0B642',
    yellow400: '#C09725',
    yellow500: '#A07B13',
    yellow600: '#806109',
    yellow700: '#604803',
    yellow800: '#403001',
    yellow900: '#201801',
    midnight30: '#373057',
    blueNav: '#041B2E',
    midnight010: '#FCF5F9',
    midnight050: '#F3D7E7',
    midnight100: '#E6B1CF',
    midnight200: '#CD70A5',
    midnight300: '#B43E81',
    midnight400: '#9B1C64',
    midnight500: '#81074C',
    midnight600: '#68003B',
    midnight700: '#4E002C',
    midnight800: '#34001D',
    midnight900: '#1A000F',
    silver: '#D2DEE7',
    blueGray: '#F1F5F8',
    green: '#F1F5F8',
    teal: '#00D2B9',
    darkGreen: '#B8EFE6',
    navy: '#041B2E',
    blue: '#0D4AC3',
    overcast: '#B0CEEF',
    midnight: '#373057',
    purple: '#916FEF',
    burgundy: '#F6C7E9',
    darkRed: '#68003B',
    red: '#FF4635',
    sunburn: '#FBD6D3',
    orange: '#B36526',
    yellow: '#E0B642',
    sand: '#EBD5BA',
  },
  fonts: {
    /** @deprecated */
    h1: {
      fontSize: '101px',
      color: 'rgba(0,0,0,0.90)',
      letterSpacing: '-1.5px',
      fontFamily: fontFamily,
      fontWeight: 300,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    /** @deprecated */
    h2: {
      fontSize: '64px',
      color: 'rgba(0,0,0,0.90)',
      letterSpacing: '-0.5px',
      fontFamily: fontFamily,
      fontWeight: 300,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    /** @deprecated */
    h3: {
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: 300,
      color: 'rgba(0,0,0,0.90)',
      fontFamily: fontFamily,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    display: {
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: 300,
      fontFamily: fontFamily,
    },
    /** @deprecated */
    h4: {
      fontSize: '28px',
      lineHeight: '40px',
      fontWeight: 500,
      color: 'rgba(0,0,0,0.90)',
      fontFamily: fontFamily,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    headlineL: {
      fontSize: '28px',
      lineHeight: '40px',
      fontWeight: 500,
      fontFamily: fontFamily,
    },
    /** @deprecated */
    h5: {
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 500,
      color: 'rgba(0,0,0,0.90)',
      fontFamily: fontFamily,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    headlineM: {
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 500,
      fontFamily: fontFamily,
    },
    /** @deprecated */
    h6: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 500,
      color: 'rgba(0,0,0,0.90)',
      fontFamily: fontFamily,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    headlineS: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 500,
      fontFamily: fontFamily,
    },
    titleL: {
      fontSize: '17px',
      lineHeight: '28px',
      fontWeight: 600,
      letterSpacing: '0.1px',
      fontFamily: fontFamily,
    },
    /** @deprecated */
    subtitle1: {
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '0.15px',
      color: 'rgba(0,0,0,0.90)',
      fontFamily: fontFamily,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    titleM: {
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '0.15px',
      fontFamily: fontFamily,
    },
    /** @deprecated */
    subtitle2: {
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: 600,
      letterSpacing: '0.25px',
      color: 'rgba(0,0,0,0.90)',
      fontFamily: fontFamily,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    titleS: {
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: 600,
      letterSpacing: '0.25px',
      fontFamily: fontFamily,
    },
    tableHeader: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      fontFamily: fontFamily,
    },
    tab: {
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      fontFamily: fontFamily,
    },
    chip: {
      fontSize: '11px',
      lineHeight: '14px',
      fontWeight: 700,
      letterSpacing: '0.6px',
      fontFamily: fontFamily,
    },
    overline2: {
      fontSize: '11px',
      lineHeight: '16px',
      fontWeight: 600,
      letterSpacing: '1px',
      textFransform: 'uppercase',
      fontFamily: fontFamily,
    },
    /** @deprecated */
    body1: {
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: 400,
      color: 'rgba(0,0,0,0.90)',
      fontFamily: fontFamily,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    bodyL: {
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: 400,
      fontFamily: fontFamily,
    },
    /** @deprecated */
    body2: {
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: 400,
      letterSpacing: '0.1px',
      color: 'rgba(0,0,0,0.90)',
      fontFamily: fontFamily,
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    bodyM: {
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: 400,
      letterSpacing: '0.1px',
      fontFamily: fontFamily,
    },
    caption: {
      fontSize: '11px',
      lineHeight: '16px',
      fontWeight: 400,
      letterSpacing: '0.2px',
      fontFamily: fontFamily,
    },
    label: {
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: 400,
      letterSpacing: '0.2px',
      fontFamily: fontFamily,
    },
    button: {
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      textTransform: 'uppercase',
      fontFamily: fontFamily,
    },
    code: {
      fontSize: '15px',
      fontWeight: 400,
      fontFamily:
        "'IBM Plex Mono', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace",
    },
    surtitle: {
      fontSize: '12px',
      lineHeight: '17px',
      fontWeight: 400,
      letterSpacing: '0.1px',
      textTransform: 'uppercase',
      fontFamily: fontFamily,
    },
    /** @deprecated */
    buttonAction: {
      fontSize: '14px',
      color: 'rgba(0,0,0,0.90)',
      letterSpacing: '1px',
      lineHeight: '20px',
      fontFamily: fontFamily,
      fontWeight: 600,
      textTransform: 'uppercase',
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    /** @deprecated */
    overline1: {
      fontSize: '12px',
      color: 'rgba(0,0,0,0.90)',
      letterSpacing: '1.5px',
      fontFamily: fontFamily,
      fontWeight: 400,
      textTransform: 'uppercase',
      textAlign: 'left',
      paragraphSpacing: 0,
    },
    iconSmall: '16px',
    fontFamily,
  },
  typographyVariants: [
    'h3-display',
    'h4-headlineL',
    'h5-headlineM',
    'h6-headlineS',
    'title-titleL',
    'subtitle1-titleM',
    'subtitle2-titleS',
    'subtitle3-tableHeader',
    'tab',
    'overline-chip',
    'overline2',
    'body1-bodyL',
    'body2-bodyM',
    'caption',
    'label',
    'button',
    'code',
    'inherit',
    'surtitle',
  ],
};

import {
  CssBaseline,
  DeprecatedThemeOptions,
  SimplePaletteColorOptions,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
  adaptV4Theme,
  createTheme,
  outlinedInputClasses,
  useTheme,
} from '@mui/material';
import { enUS } from '@mui/material/locale';
import useLocalStorage from '@rehooks/local-storage';
import deepmerge from 'deepmerge';
import { Settings } from 'luxon';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { designTokens } from './Theme.design-tokens';
import {
  getLocaleBasedOnLanguage,
  supportedLocales,
  useLocaleConfig,
} from './utils';

Settings.defaultLocale = 'en';

export type ZuoraDensity = 'default' | 'comfortable' | 'condensed';

declare module '@mui/material/styles' {
  interface Theme {
    locale?: string;
    language?: string;
    density: ZuoraDensity;
  }

  interface TypographyVariants {
    display: React.CSSProperties;
    headlineL: React.CSSProperties;
    headlineM: React.CSSProperties;
    headlineS: React.CSSProperties;
    title: React.CSSProperties;
    titleL: React.CSSProperties;
    titleM: React.CSSProperties;
    titleS: React.CSSProperties;
    tableHeader: React.CSSProperties;
    subtitle3: React.CSSProperties;
    tab: React.CSSProperties;
    chip: React.CSSProperties;
    overline2: React.CSSProperties;
    bodyL: React.CSSProperties;
    bodyM: React.CSSProperties;
    label: React.CSSProperties;
    code: React.CSSProperties;
    surtitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display: React.CSSProperties;
    headlineL: React.CSSProperties;
    headlineM: React.CSSProperties;
    headlineS: React.CSSProperties;
    title: React.CSSProperties;
    titleL: React.CSSProperties;
    titleM: React.CSSProperties;
    titleS: React.CSSProperties;
    tableHeader: React.CSSProperties;
    subtitle3: React.CSSProperties;
    tab: React.CSSProperties;
    chip: React.CSSProperties;
    overline2: React.CSSProperties;
    bodyL: React.CSSProperties;
    bodyM: React.CSSProperties;
    label: React.CSSProperties;
    code: React.CSSProperties;
    surtitle: React.CSSProperties;
  }
}

const theme = createTheme();

// CONSTANTS
export const brand500Colors = [
  designTokens.colors.navy500,
  designTokens.colors.teal500,
  designTokens.colors.red500,
  designTokens.colors.aqua500,
  designTokens.colors.blueGray500,
  designTokens.colors.gray500,
];

export const boxShadows = theme.shadows;

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    indeterminate: { main: string };
  }

  interface PaletteOptions {
    indeterminate?: { light?: string; main: string };
  }
}

declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    indeterminate: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display: true;
    headlineL: true;
    headlineM: true;
    headlineS: true;
    title: true;
    titleL: true;
    titleM: true;
    titleS: true;
    tableHeader: true;
    subtitle3: true;
    tab: true;
    chip: true;
    overline2: true;
    bodyL: true;
    bodyM: true;
    label: true;
    code: true;
    surtitle: true;
  }
}

const basePalette: DeprecatedThemeOptions['palette'] = {
  common: {
    black: designTokens.colors.black,
    white: designTokens.colors.white,
  },
  grey: {
    50: designTokens.colors.gray050,
    100: designTokens.colors.gray100,
    200: designTokens.colors.gray200,
    300: designTokens.colors.gray300,
    400: designTokens.colors.gray400,
    500: designTokens.colors.gray500,
    600: designTokens.colors.gray600,
    700: designTokens.colors.gray700,
    800: designTokens.colors.gray800,
    900: designTokens.colors.gray900,
  },
};

export const lightPalette = deepmerge(basePalette, {
  default: {
    contrastText: 'white',
  },
  primary: {
    main: designTokens.colors.lightLink,
    dark: designTokens.colors.blue600,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  secondary: {
    main: designTokens.colors.teal600,
    dark: designTokens.colors.teal700,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  error: {
    light: designTokens.colors.red050,
    main: designTokens.colors.red500,
    dark: designTokens.colors.red600,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  warning: {
    light: designTokens.colors.yellow050,
    main: designTokens.colors.yellow300,
    dark: designTokens.colors.yellow400,
    contrastText: designTokens.colors.black,
  },
  info: {
    light: designTokens.colors.blue050,
    main: designTokens.colors.blue400,
    dark: designTokens.colors.blue500,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  success: {
    light: designTokens.colors.green050,
    main: designTokens.colors.green500,
    dark: designTokens.colors.green600,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  indeterminate: {
    main: designTokens.colors.coolGray500,
    dark: designTokens.colors.coolGray600,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  background: {
    default: designTokens.colors.lightPageBackground,
  },
  action: {
    hoverOpacity: 0.08,
    disabledOpacity: 0.5,
    focusOpacity: 0.24,
  },
});

export const darkPalette = deepmerge(basePalette, {
  primary: {
    main: designTokens.colors.darkLink,
    dark: designTokens.colors.blue200,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  secondary: {
    main: designTokens.colors.teal100,
    dark: designTokens.colors.teal050,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  error: {
    main: designTokens.colors.red400,
    dark: designTokens.colors.red300,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  warning: {
    main: designTokens.colors.yellow300,
    dark: designTokens.colors.yellow200,
    contrastText: designTokens.colors.black,
  },
  info: {
    main: designTokens.colors.blue400,
    dark: designTokens.colors.blue300,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  success: {
    main: designTokens.colors.green400,
    dark: designTokens.colors.green300,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  indeterminate: {
    main: designTokens.colors.coolGray300,
    dark: designTokens.colors.darkEmphasisLow,
    contrastText: designTokens.colors.darkEmphasisHigh,
  },
  type: 'dark',
  background: {
    default: designTokens.colors.darkPageBackground,
    paper: designTokens.colors.navy700,
  },
  action: {
    hoverOpacity: 0.08,
    disabledOpacity: 0.5,
    focusOpacity: 0.24,
  },
});

export const themeTokens = {
  light: {
    emphasisLow: designTokens.colors.lightEmphasisLow,
    emphasisMedium: designTokens.colors.lightEmphasisMedium,
    emphasisHigh: designTokens.colors.lightEmphasisHigh,
    contextLow: designTokens.colors.lightContextLow,
    contextMedium: designTokens.colors.lightContextMedium,
    contextHigh: designTokens.colors.lightContextHigh,
  },
  dark: {
    emphasisLow: designTokens.colors.darkEmphasisLow,
    emphasisMedium: designTokens.colors.darkEmphasisMedium,
    emphasisHigh: designTokens.colors.darkEmphasisHigh,
    contextLow: designTokens.colors.darkContextLow,
    contextMedium: designTokens.colors.darkContextMedium,
    contextHigh: designTokens.colors.darkContextHigh,
  },
} as const;

const iconSizes = {
  '&.MuiIcon-fontSizeInherit': {
    fontSize: 'inherit',
  },
  '&.MuiIcon-fontSizeSmall': {
    fontSize: designTokens.fonts.iconSmall,
  },
};

const typography = {
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'sans-serif',
  ].join(','),
  h1: undefined,
  h2: undefined,
  h3: {
    ...theme.typography.h3,
    ...designTokens.fonts.display,
  },
  display: {
    ...theme.typography.h3,
    ...designTokens.fonts.display,
  },
  h4: {
    ...theme.typography.h4,
    ...designTokens.fonts.headlineL,
  },
  headlineL: {
    ...theme.typography.h4,
    ...designTokens.fonts.headlineL,
  },
  h5: {
    ...theme.typography.h5,
    ...designTokens.fonts.headlineM,
  },
  headlineM: {
    ...theme.typography.h5,
    ...designTokens.fonts.headlineM,
  },
  h6: {
    ...theme.typography.h6,
    ...designTokens.fonts.headlineS,
  },
  headlineS: {
    ...theme.typography.h6,
    ...designTokens.fonts.headlineS,
  },
  title: {
    ...theme.typography.subtitle1,
    ...designTokens.fonts.titleL,
  },
  titleL: {
    ...theme.typography.subtitle1,
    ...designTokens.fonts.titleL,
  },
  subtitle1: {
    ...theme.typography.subtitle1,
    ...designTokens.fonts.titleM,
  },
  titleM: {
    ...theme.typography.subtitle1,
    ...designTokens.fonts.titleM,
  },
  subtitle2: {
    ...theme.typography.subtitle2,
    ...designTokens.fonts.titleS,
  },
  titleS: {
    ...theme.typography.subtitle2,
    ...designTokens.fonts.titleS,
  },
  subtitle3: {
    ...theme.typography.subtitle2,
    ...designTokens.fonts.tableHeader,
  },
  tableHeader: {
    ...theme.typography.subtitle2,
    ...designTokens.fonts.tableHeader,
  },
  tab: {
    ...theme.typography.button,
    ...designTokens.fonts.tab,
  },
  overline: {
    ...theme.typography.overline,
    ...designTokens.fonts.chip,
  },
  chip: {
    ...theme.typography.overline,
    ...designTokens.fonts.chip,
  },
  overline2: {
    ...theme.typography.overline,
    ...designTokens.fonts.overline2,
  },
  body1: {
    ...theme.typography.body1,
    ...designTokens.fonts.bodyL,
  },
  bodyL: {
    ...theme.typography.body1,
    ...designTokens.fonts.bodyL,
  },
  body2: {
    ...theme.typography.body2,
    ...designTokens.fonts.bodyM,
  },
  bodyM: {
    ...theme.typography.body2,
    ...designTokens.fonts.bodyM,
  },
  caption: {
    ...theme.typography.caption,
    ...designTokens.fonts.caption,
  },
  label: {
    ...theme.typography.caption,
    ...designTokens.fonts.label,
  },
  button: {
    ...theme.typography.button,
    ...designTokens.fonts.button,
  },
  surtitle: {
    ...theme.typography.overline,
    ...designTokens.fonts.surtitle,
  },
};

// CREATOR FUNCTIONS
const createInputErrorState = (palette: DeprecatedThemeOptions['palette']) => ({
  '& fieldset': {
    width: '100%',
  },
  '& input': {
    flexGrow: 1,
  },
  '& *:not(fieldset)': {
    zIndex: 1,
  },
  '&::before': {
    content: "'error_outline'",
    borderRadius: 'inherit',
    // @ts-ignore
    color: palette?.error?.main,
    // @ts-ignore
    border: `1px solid ${palette?.error?.main}`,
    borderLeft: 'none',
    fontFamily: 'Material Icons Outlined',
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1.25)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    fontSize: '20px',
    opacity: 0,
  },
  '&::after': {
    display: 'flex',
    opacity: 0,
    content: "''",
    flexBasis: '0px',
  },
  '&.Mui-error': {
    paddingRight: '0px',
    '& fieldset': {
      width: 'calc(100% - 36px)',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: 'none',
    },
    '& .MuiSelect-icon, .MuiAutocomplete-endAdornment': {
      transform: 'translateX(-36px)',
    },
    '&::after': {
      flexBasis: '51px',
    },
    '&::before': {
      opacity: 1,
    },
    '&.MuiInputBase-multiline': {
      '&::before': {
        alignItems: 'flex-start',
      },
    },
    '&.Mui-focused': {
      '&::before': {
        paddingRight: '7px',
        paddingLeft: '9px',
      },
    },
  },
});

/** Some tokens are theme specific. This convenience method exposes
 * a subset of the most critical theme-specific emphasis/context tokens. */
export const useThemeTokens = (invert = false) => {
  const theme = useTheme();
  const mode = !invert
    ? theme.palette.mode
    : theme.palette.mode === 'light'
    ? 'dark'
    : 'light';
  return themeTokens[mode];
};

// THEMES
export const baseTheme: DeprecatedThemeOptions = {
  overrides: {
    MuiAccordion: {
      rounded: {
        '&div:first-of-type': {
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
        },
        '&div:last-of-type': {
          borderBottomLeftRadius: 2,
          borderBottomRightRadius: 2,
        },
      },
    },
    MuiAccordionActions: {
      root: {
        '&:empty': {
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: theme.spacing(2),
      },
    },
    MuiAccordionSummary: {
      root: {
        '&$expanded': {
          minHeight: 0,
        },
      },
      content: {
        '&$expanded': {
          margin: `${theme.spacing(2)} 0`,
        },
        fontSize: '17px',
        lineHeight: '28px',
        fontFamily: 'BlinkMacSystemFont, sans-serif',
        fontWeight: 600,
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: `${theme.spacing(2)} 0`,
      },
    },
    MuiAlert: {
      root: {
        padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
        '& .MuiAlertTitle-root': {
          marginBottom: `${theme.spacing(0.5)}`,
          ...designTokens.fonts.titleM,
        },
        '& .MuiAlert-message span': {
          ...designTokens.fonts.bodyM,
        },
      },
      icon: {
        fontSize: '20px',
        padding: `${theme.spacing(1)} 0`,
        marginRight: `${theme.spacing(1)}`,
        '& .MuiCircularProgress-root': {
          marginTop: `${theme.spacing(0.25)}`,
        },
      },
      action: {
        '& .MuiButton-root': {
          minWidth: 0,
          padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        },
        '& .MuiSvgIcon-root': {
          fontSize: '16px',
          margin: '3px 2px 0 2px',
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 500,
        textTransform: 'capitalize',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4,
      },
    },
    MuiCardHeader: {
      title: {
        fontSize: '17px',
        display: 'flex',
        lineHeight: '28px',
        fontWeight: 600,
      },
    },
    MuiCheckbox: {
      root: {
        padding: theme.spacing(1),
        '& .MuiSvgIcon-root': {
          zIndex: 1,
          pointerEvents: 'none',
        },
      },
    },
    MuiChip: {
      root: {
        height: 'auto',
        '&.MuiChip-colorDefault': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.coolGray300}`,
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.lightEmphasisMedium,
          },
        },
        '&.MuiChip-colorWarning': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.yellow500}`,
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.lightEmphasisMedium,
          },
        },
        '&.MuiChip-colorInfo': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.blue600}`,
          },
        },
        '&.MuiChip-colorSuccess': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.green700}`,
          },
        },
        '&.MuiChip-colorError': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.red700}`,
          },
        },
        '&.MuiChip-colorIndeterminate': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.coolGray700}`,
          },
        },
      },
      icon: {
        color: 'inherit',
        fontSize: '16px',
      },
      deleteIcon: {
        color: 'inherit',
        marginLeft: '-2px',
        fontSize: '16px',
      },
      deleteIconMedium: {
        fontSize: '16px',
      },
      sizeSmall: {
        lineHeight: '14px',
        letterSpacing: '0.6px',
        fontWeight: 700,
        fontSize: '10px',
      },
      sizeMedium: {
        lineHeight: '16px',
        letterSpacing: '1px',
        fontWeight: 600,
        fontSize: '11px',
      },
      labelSmall: {
        padding: '2px 4px',
      },
      labelMedium: {
        padding: '2px 6px',
      },
      iconSmall: {
        marginRight: '-2px',
      },
      iconMedium: {
        marginRight: '0',
      },
      outlined: {
        borderWidth: '2px',
        color: designTokens.colors.lightEmphasisHigh,
        '& .MuiChip-deleteIcon': {
          color: designTokens.colors.lightEmphasisMedium,
        },
        '&.MuiChip-colorDefault': {
          borderColor: designTokens.colors.coolGray200,
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.coolGray300,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.lightEmphasisMedium,
          },
        },
        '&.MuiChip-colorWarning': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.yellow500,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.lightEmphasisMedium,
          },
        },
        '&.MuiChip-colorInfo': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.blue600,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.lightEmphasisMedium,
          },
        },
        '&.MuiChip-colorSuccess': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.green600,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.lightEmphasisMedium,
          },
        },
        '&.MuiChip-colorError': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.red600,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.lightEmphasisMedium,
          },
        },
        '&.MuiChip-colorIndeterminate': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.coolGray700,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.lightEmphasisMedium,
          },
        },
      },
    },
    MuiContainer: {
      root: {
        '&.MuiContainer-maxWidthLg': {
          maxWidth: '100%',
          padding: 0,
        },
      },
    },
    MuiCssBaseline: {
      body: {
        fontSize: '0.875rem',
        lineHeight: 1.43,
      },
    },
    MuiDialogActions: {
      root: {
        '& .MuiButton-root': {
          boxShadow: 'none',
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0,
      },
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiInputBase: {
      root: {
        lineHeight: 1.1876,
        fontSize: typography.bodyM.fontSize,
        height: '36px',
        '&.Mui-focused': {
          '&::before': {
            borderWidth: '2px',
          },
        },
        '.material-icons': {
          fontSize: '20px',
        },
        '.MuiSvgIcon-root': {
          fontSize: '1.25rem',
        },
        [`&:hover:not(.Mui-disabled, .Mui-error,) :not(input['readonly']) + .${outlinedInputClasses.notchedOutline}`]:
          {
            border: `2px solid`,
          },
        '&:hover.Mui-error': {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            border: `2px solid ${designTokens.colors.lightError}`,
            borderRight: 'none',
          },
          '&:before': {
            border: `2px solid`,
            borderLeft: 'none',
          },
        },
        input: {
          '&:-webkit-autofill': {
            marginLeft: '2px',
            marginRight: '2px',
            padding: '5px 14px',
            borderRadius: 'unset',
          },
        },
      },
      multiline: {
        height: 'initial',
      },
    },
    MuiIconButton: {
      root: {
        padding: '12px',
      },
      sizeSmall: {
        padding: '3px',
      },
    },
    MuiInputLabel: {
      root: {
        transform: 'none !important',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      },
    },
    MuiFormControl: {
      root: {
        '&>.MuiCheckbox-root': {
          alignSelf: 'flex-start',
        },
      },
    },
    MuiFormControlLabel: {
      // @ts-ignore
      root: { ...typography.bodyM },
      label: {
        fontSize: typography.bodyM.fontSize,
        div: {
          display: 'flex',
          alignItems: 'flex-end',
        },
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 0,
      },
      notchedOutline: {
        top: 0,
        '& legend': {
          height: 0,
        },
      },
    },
    MuiFormLabel: {
      // @ts-ignore
      root: {
        ...designTokens.fonts.titleS,
        marginBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
      },
      asterisk: {
        color: designTokens.colors.lightError,
        marginBottom: '-0.3rem',
        fontSize: '16px',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.8,
        },

        '&:active': {
          opacity: 0.6,
        },
      },
    },
    MuiRadio: {
      root: {
        '& .MuiSvgIcon-root': {
          zIndex: 1,
          pointerEvents: 'none',
        },
      },
    },
    MuiSelect: {
      select: {
        minHeight: 'initial',
      },
    },
    MuiSwitch: {
      root: {
        '& .MuiSwitch-thumb': {
          zIndex: 1,
          pointerEvents: 'none',
        },
      },
    },
    MuiTab: {
      root: {
        fontWeight: 700,
        letterSpacing: '1.1px',
        textTransform: 'capitalize',
      },
    },
    MuiTabs: {
      root: {
        '&.childTabs .MuiTabs-indicator': {
          display: 'none',
        },
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'inherit',
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: 'none',
      },
      selectLabel: {
        color: designTokens.colors.lightEmphasisMedium,
      },
      actions: {
        color: designTokens.colors.lightEmphasisMedium,
      },
      displayedRows: {
        color: designTokens.colors.black,
      },
      select: {
        fontSize: '13px',
        color: designTokens.colors.lightEmphasisHigh,
        paddingTop: '8px',
        border: '1px solid transparent',
        borderRadius: '4px',
        marginBottom: '2px',
        transition: '.3s',
        '&:hover': {
          border: `1px solid ${designTokens.colors.coolGray900}`,
          borderRadius: '4px',
          transition: '.3s',
        },
        '&:active': {
          border: `2px solid ${designTokens.colors.blue500}`,
          transition: '.3s',
        },
      },
      menuItem: {
        fontSize: '13px',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: theme.spacing(1),
      },
    },
    MuiStep: {
      alternativeLabel: {
        marginBottom: 'auto',
      },
    },
    MuiStepLabel: {
      root: {
        '& .MuiStepLabel-alternativeLabel.MuiStepLabel-label': {
          marginTop: '0 !important',
        },
      },
      iconContainer: {
        '&::before': {
          backgroundColor: designTokens.colors.white,
          height: '20px',
          width: '20px',
          margin: '2px',
          position: 'absolute',
          zIndex: '-1',
          borderRadius: '100%',
          content: "''",
        },
      },
    },
    MuiStepIcon: {
      text: {
        fontSize: '13px',
        fontWeight: 400,
        fill: designTokens.colors.white,
      },
    },
    MuiStepButton: {
      root: {
        padding: '0 1rem',
        '&:active': {
          background: designTokens.colors.lightSelection,
        },
      },
    },
    // @ts-ignore
    MuiTimelineContent: {
      root: {
        maxWidth: 'calc(100% - 28px)',
      },
    },
  },
  props: {
    MuiInputLabel: {
      shrink: true,
    },
    MuiLink: {
      underline: 'hover',
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiTypography: {
      variantMapping: {
        display: 'h3',
        headlineL: 'h4',
        headlineM: 'h5',
        headlineS: 'h6',
        title: 'h6',
        titleL: 'h6',
        titleM: 'h6',
        titleS: 'h6',
        tableHeader: 'h6',
        subtitle3: 'h6',
        bodyL: 'p',
        bodyM: 'p',
      },
    },
  },
  // @ts-ignore
  typography,
  palette: basePalette,
};

export const lightThemeStyles = deepmerge(baseTheme, {
  overrides: {
    MuiAccordionDetails: {
      root: {
        borderTop: `1px solid ${designTokens.colors.lightContextHigh}`,
      },
    },
    MuiAlert: {
      message: {
        color: designTokens.colors.lightEmphasisHigh,
      },
      standardError: {
        backgroundColor: (lightPalette.error as SimplePaletteColorOptions)
          .light,
        '& .MuiAlert-icon': {
          color: (lightPalette.error as SimplePaletteColorOptions).main,
        },
      },
      standardWarning: {
        backgroundColor: (lightPalette.warning as SimplePaletteColorOptions)
          .light,
        '& .MuiAlert-icon': {
          color: (lightPalette.warning as SimplePaletteColorOptions).main,
        },
      },
      standardInfo: {
        backgroundColor: (lightPalette.info as SimplePaletteColorOptions).light,
        '& .MuiAlert-icon': {
          color: (lightPalette.info as SimplePaletteColorOptions).main,
        },
      },
      standardSuccess: {
        backgroundColor: (lightPalette.success as SimplePaletteColorOptions)
          .light,
        '& .MuiAlert-icon': {
          color: (lightPalette.success as SimplePaletteColorOptions).main,
        },
      },
      outlined: {
        backgroundColor: lightPalette.common.white,
        borderColor: themeTokens.light.contextHigh,
      },
      outlinedError: {
        '& .MuiAlert-icon': {
          color: (lightPalette.error as SimplePaletteColorOptions).main,
        },
      },
      outlinedWarning: {
        '& .MuiAlert-icon': {
          color: (lightPalette.warning as SimplePaletteColorOptions).main,
        },
      },
      outlinedInfo: {
        '& .MuiAlert-icon': {
          color: (lightPalette.info as SimplePaletteColorOptions).main,
        },
      },
      outlinedSuccess: {
        '& .MuiAlert-icon': {
          color: (lightPalette.success as SimplePaletteColorOptions).main,
        },
      },
    },
    MuiButton: {
      root: {
        '&.tertiary': {
          color: designTokens.colors.lightEmphasisHigh,
          borderColor: designTokens.colors.lightEmphasisLow,
        },
        '&.tertiary:hover': {
          background: 'rgba(34, 51, 65, 0.04)',
        },
        '&.Mui-disabled': {
          color: designTokens.colors.lightEmphasisLow,
          borderColor: designTokens.colors.lightContextHigh,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&.Mui-disabled': {
          color: designTokens.colors.coolGray100,
        },
        '&:hover': {
          backgroundColor: designTokens.colors.lightActionHover,
          opacity: 1,
        },
        '& .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible': {
          color: designTokens.colors.lightActionActive,
          opacity: 1,
        },
        '& .MuiTouchRipple-ripple.MuiTouchRipple-ripplePulsate': {
          color: designTokens.colors.lightActionFocus,
          opacity: 1,
        },
      },
    },
    MuiCollapse: {
      root: {
        overflow: 'auto',
      },
      wrapper: {
        marginTop: '5px',
      },
    },
    MuiTabs: {
      root: {
        '& :hover': {
          color: lightPalette.secondary.main,
        },
        '&.childTabs:not(.MuiTabs-vertical) .Mui-selected': {
          boxShadow: `inset 1px 1px ${designTokens.colors.coolGray200}, inset -1px 1px ${designTokens.colors.coolGray200}`,
          borderRadius: '4px 4px 0 0',
        },
        '&.childTabs.MuiTabs-vertical .Mui-selected': {
          boxShadow: `inset 1px 1px ${designTokens.colors.coolGray200}, inset 1px -1px ${designTokens.colors.coolGray200}`,
          borderRadius: '4px 0 0 4px',
        },
        '&:not(.MuiTabs-vertical) .Mui-focusVisible, &.childTabs:not(.MuiTabs-vertical) .Mui-focusVisible':
          {
            boxShadow: `inset 2px 2px ${designTokens.colors.lightLink}, inset -2px 2px ${designTokens.colors.lightLink}`,
            borderRadius: '4px 4px 0 0',
          },
        '&.MuiTabs-vertical .Mui-focusVisible, &.childTabs.MuiTabs-vertical .Mui-focusVisible':
          {
            boxShadow: `inset 2px 2px ${designTokens.colors.lightLink}, inset 2px -2px ${designTokens.colors.lightLink}`,
            borderRadius: '4px 0 0 4px',
          },
      },
    },
    MuiTableCell: {
      head: {
        '& *': {
          color: designTokens.colors.lightEmphasisHigh,
        },
        '& .MuiIcon-root': {
          color: designTokens.colors.lightEmphasisHigh,
        },
        '& MuiTypography-root': {
          color: `${designTokens.colors.burgundy500} !important`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: designTokens.colors.coolGray800,
        '&.Mui-error': {
          color: designTokens.colors.coolGray800,
        },
        '&.Mui-focused': {
          color: designTokens.colors.coolGray800,
        },
        MuiLink: {
          root: {
            '&:visited': {
              color: designTokens.colors.purple500,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: designTokens.colors.lightActionHover,
        },
        '&.Mui-selected': {
          backgroundColor: designTokens.colors.lightActionSelected,
        },
        '&.Mui-focusVisible': {
          backgroundColor: designTokens.colors.lightActionHoverSelected,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        color: themeTokens.light.emphasisHigh,
      },
    },
    MuiIcon: {
      root: {
        ...iconSizes,
      },
      colorAction: {
        color: themeTokens.light.emphasisMedium,
      },
    },
    MuiIconButton: {
      root: {
        color: themeTokens.light.emphasisMedium,
      },
    },
    MuiInputBase: {
      root: {
        ...createInputErrorState(lightPalette),
        '& input': {
          color: designTokens.colors.lightEmphasisHigh,
        },
        '& input::placeholder': {
          color: designTokens.colors.lightEmphasisLow,
        },
        '& input:disabled': {
          textFillColor: designTokens.colors.lightEmphasisLow,
        },
        '&.Mui-disabled > fieldset': {
          border: `1px dashed ${designTokens.colors.lightContextExtraHigh}`,
        },
        '& fieldset': {
          borderColor: designTokens.colors.lightContextExtraHigh,
        },
      },
    },
    MuiFormControl: {
      root: {
        '&.ReadOnly': {
          '& .MuiOutlinedInput-notchedOutline': {
            background: designTokens.colors.lightPageBackground,
          },
          '& .Mui-focused > fieldset': {
            border: `1px solid ${designTokens.colors.lightContextExtraHigh} !important`,
          },
          '& .MuiOutlinedInput-root:hover > fieldset': {
            borderColor: designTokens.colors.lightContextExtraHigh,
          },
        },
      },
    },
    MuiRadio: {
      root: {
        '&.Mui-disabled': {
          color: designTokens.colors.coolGray100,
        },
        '&:hover': {
          backgroundColor: designTokens.colors.lightActionHover,
          opacity: 1,
        },
        '& .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible': {
          color: designTokens.colors.lightActionActive,
          opacity: 1,
        },
        '& .MuiTouchRipple-ripple.MuiTouchRipple-ripplePulsate': {
          color: designTokens.colors.lightActionFocus,
          opacity: 1,
        },
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: designTokens.colors.lightContextHigh,
      },
    },
    MuiStepButton: {
      root: {
        '& .MuiTypography-caption': {
          color: designTokens.colors.lightEmphasisMedium,
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: designTokens.colors.coolGray300,
      },
    },
    MuiSwitch: {
      switchBase: {
        '&.Mui-disabled': {
          color: designTokens.colors.white,
        },
        '&:hover': {
          backgroundColor: designTokens.colors.lightActionHover,
          opacity: 1,
        },
        '& .MuiTouchRipple-rippleVisible': {
          color: designTokens.colors.lightActionActive,
          opacity: 1,
        },
        '& .MuiTouchRipple-ripplePulsate': {
          color: designTokens.colors.lightActionFocus,
          opacity: 1,
        },
        '&.Mui-checked': {
          '&+.MuiSwitch-track': {
            opacity: 0.38,
          },
          '&.Mui-disabled': {
            color: designTokens.colors.white,
          },
          '&.Mui-disabled+.MuiSwitch-track': {
            backgroundColor: designTokens.colors.black,
            opacity: 0.12,
          },
          '&:hover': {
            backgroundColor: designTokens.colors.lightActionHover,
            opacity: 1,
          },
        },
      },
    },
  },
  palette: lightPalette,
});

export const darkThemeStyles = deepmerge(baseTheme, {
  overrides: {
    MuiAccordionDetails: {
      root: {
        borderTop: `1px solid ${designTokens.colors.darkContextHigh}`,
      },
    },
    MuiAlert: {
      root: {
        '& .MuiAlertTitle-root': {
          color: designTokens.colors.white,
        },
        '& .MuiAlert-message span': {
          color: designTokens.colors.white,
        },
      },
      action: {
        '& .MuiSvgIcon-root': {
          color: designTokens.colors.white,
        },
      },
    },
    MuiButton: {
      root: {
        '&.tertiary': {
          border: `1px solid ${designTokens.colors.darkEmphasisLow}`,
        },
        '&.tertiary:hover': {
          background: designTokens.colors.darkContextMedium,
        },
        '&.Mui-disabled': {
          color: designTokens.colors.darkEmphasisLow,
          borderColor: designTokens.colors.darkContextHigh,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: designTokens.colors.white,
        '&.Mui-disabled': {
          color: designTokens.colors.darkEmphasisLow,
        },
        '&:hover': {
          backgroundColor: designTokens.colors.darkActionHover,
          opacity: 1,
        },
        '& .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible': {
          color: designTokens.colors.darkActionActive,
          opacity: 1,
        },
        '&.Mui-focusVisible': {
          color: designTokens.colors.white,
        },
        '& .MuiTouchRipple-ripple.MuiTouchRipple-ripplePulsate': {
          color: designTokens.colors.darkActionFocus,
          opacity: 1,
        },
      },
    },
    MuiChip: {
      root: {
        '&.MuiChip-colorDefault': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.darkEmphasisHigh}`,
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.darkEmphasisMedium,
          },
        },
        '&.MuiChip-colorWarning': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.darkEmphasisHigh}`,
          },
        },
        '&.MuiChip-colorInfo': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.darkEmphasisHigh}`,
          },
        },
        '&.MuiChip-colorSuccess': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.darkEmphasisHigh}`,
          },
        },
        '&.MuiChip-colorError': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.darkEmphasisHigh}`,
          },
        },
        '&.MuiChip-colorIndeterminate': {
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            boxShadow: `inset 0 0 0 2px ${designTokens.colors.darkEmphasisHigh}`,
          },
        },
      },
      '& .MuiChip-deleteIcon': {
        color: designTokens.colors.red, //darkEmphasisMedium,
      },
      outlined: {
        color: designTokens.colors.darkEmphasisHigh,
        borderColor: designTokens.colors.darkEmphasisHigh,
        '& .MuiChip-deleteIcon': {
          color: designTokens.colors.darkEmphasisMedium,
        },
        '&.MuiChip-colorDefault': {
          borderColor: designTokens.colors.coolGray400,
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.coolGray400,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.darkEmphasisMedium,
          },
        },
        '&.MuiChip-colorWarning': {
          borderColor: designTokens.colors.darkWarning,
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.darkWarning,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.darkEmphasisMedium,
          },
        },
        '&.MuiChip-colorInfo': {
          borderColor: designTokens.colors.blue400,
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.blue400,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.darkEmphasisMedium,
          },
        },
        '&.MuiChip-colorSuccess': {
          borderColor: designTokens.colors.green400,
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.green400,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.darkEmphasisMedium,
          },
        },
        '&.MuiChip-colorError': {
          borderColor: designTokens.colors.darkError,
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.darkError,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.darkEmphasisMedium,
          },
        },
        '&.MuiChip-colorIndeterminate': {
          borderColor: designTokens.colors.darkEmphasisMedium,
          '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
            borderColor: designTokens.colors.darkEmphasisMedium,
            boxShadow: 'none',
          },
          '& .MuiChip-deleteIcon': {
            color: designTokens.colors.darkEmphasisMedium,
          },
        },
        '&.MuiChip-deletable:hover, &.MuiChip-deletable:focus': {
          backgroundColor: designTokens.colors.darkContextHigh,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        '& .MuiIconButton-root': {
          color: designTokens.colors.white,
        },
      },
    },
    MuiRadio: {
      root: {
        color: designTokens.colors.white,
        '&.Mui-disabled': {
          color: designTokens.colors.darkEmphasisLow,
        },
        '&:hover': {
          backgroundColor: designTokens.colors.darkActionHover,
          opacity: 1,
        },
        '& .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible': {
          color: designTokens.colors.darkActionActive,
          opacity: 1,
        },
        '&.Mui-focusVisible': {
          color: designTokens.colors.white,
        },
        '& .MuiTouchRipple-ripple.MuiTouchRipple-ripplePulsate': {
          color: designTokens.colors.darkActionFocus,
          opacity: 1,
        },
      },
    },
    MuiTabs: {
      root: {
        '& :hover': {
          color: darkPalette.secondary.main,
        },
        '&.childTabs:not(.MuiTabs-vertical) .Mui-selected': {
          boxShadow: `inset 1px 1px ${designTokens.colors.coolGray200}, inset -1px 1px ${designTokens.colors.coolGray200}`,
          borderRadius: '4px 4px 0 0',
        },
        '&.childTabs.MuiTabs-vertical .Mui-selected': {
          boxShadow: `inset 1px 1px ${designTokens.colors.coolGray200}, inset 1px -1px ${designTokens.colors.coolGray200}`,
          borderRadius: '4px 0 0 4px',
        },
        '&:not(.MuiTabs-vertical) .Mui-focusVisible, &.childTabs:not(.MuiTabs-vertical) .Mui-focusVisible':
          {
            boxShadow: `inset 2px 2px ${designTokens.colors.darkLink}, inset -2px 2px ${designTokens.colors.darkLink}`,
            borderRadius: '4px 4px 0 0',
          },
        '&.MuiTabs-vertical .Mui-focusVisible, &.childTabs.MuiTabs-vertical .Mui-focusVisible':
          {
            boxShadow: `inset 2px 2px ${designTokens.colors.darkLink}, inset 2px -2px ${designTokens.colors.darkLink}`,
            borderRadius: '4px 0 0 4px',
          },
      },
    },
    MuiTablePagination: {
      selectLabel: {
        color: designTokens.colors.white,
      },
      actions: {
        color: designTokens.colors.white,
      },
      displayedRows: {
        color: designTokens.colors.white,
      },
      select: {
        color: designTokens.colors.white,
        '&:hover': {
          border: `1px solid ${designTokens.colors.coolGray300}`,
        },
      },
    },
    MuiTypography: {
      root: {
        color: designTokens.colors.white,
      },
    },
    MuiTableCell: {
      head: {
        '& *': {
          color: designTokens.colors.darkEmphasisHigh,
        },
      },
    },
    MuiSelect: {
      select: {
        '& > span': {
          color: designTokens.colors.darkEmphasisHigh,
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        color: designTokens.colors.white,
        '&+.MuiSwitch-track': {
          opacity: 0.6,
        },
        '&.Mui-disabled': {
          color: designTokens.colors.coolGray200,
        },
        '&.Mui-disabled+.MuiSwitch-track': {
          backgroundColor: designTokens.colors.coolGray500,
          opacity: 1,
        },
        '&:hover': {
          backgroundColor: designTokens.colors.darkActionHover,
          opacity: 1,
        },
        '& .MuiTouchRipple-rippleVisible': {
          color: designTokens.colors.darkActionActive,
          opacity: 1,
        },
        '& .MuiTouchRipple-ripplePulsate': {
          color: designTokens.colors.darkActionFocus,
          opacity: 1,
        },
        '&.Mui-checked': {
          color: designTokens.colors.blue400,
          '&+.MuiSwitch-track': {
            opacity: 1,
          },
          '&.Mui-disabled': {
            color: designTokens.colors.coolGray200,
          },
          '&.Mui-disabled+.MuiSwitch-track': {
            backgroundColor: designTokens.colors.coolGray500,
            opacity: 1,
          },
          '&:hover': {
            backgroundColor: designTokens.colors.darkActionHover,
            opacity: 1,
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: themeTokens.dark.emphasisMedium,
        '&.Mui-error': {
          color: themeTokens.dark.emphasisMedium,
        },
        '&.Mui-focused': {
          color: themeTokens.dark.emphasisMedium,
        },
      },
    },
    MuiMenuItem: {
      root: {
        '& > span': {
          color: themeTokens.dark.emphasisLow,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        color: themeTokens.dark.emphasisHigh,
      },
    },
    MuiIcon: {
      root: {
        ...iconSizes,
      },
      colorAction: {
        color: themeTokens.dark.emphasisHigh,
      },
    },
    MuiIconButton: {
      root: {
        color: themeTokens.dark.emphasisMedium,
      },
    },
    MuiInputBase: {
      root: {
        ...createInputErrorState(darkPalette),
        '& input': {
          color: designTokens.colors.darkEmphasisHigh,
        },
        '& input::placeholder': {
          color: designTokens.colors.darkEmphasisLow,
        },
        '& input:disabled': {
          textFillColor: designTokens.colors.darkEmphasisLow,
        },
        '&.Mui-disabled > fieldset': {
          border: `1px dashed ${designTokens.colors.darkContextHigh}`,
        },
        '& fieldset': {
          borderColor: designTokens.colors.darkContextHigh,
        },
        [`&:hover:not(.Mui-disabled, .Mui-error,) :not(input['readonly']) + .${outlinedInputClasses.notchedOutline}`]:
          {
            border: `2px solid`,
          },
        '&:hover.Mui-error': {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            border: `2px solid ${designTokens.colors.darkError}`,
            borderRight: 'none',
          },
          '&:before': {
            border: `2px solid ${designTokens.colors.darkError}`,
            borderLeft: 'none',
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        '&.ReadOnly': {
          '& .MuiOutlinedInput-notchedOutline': {
            background: designTokens.colors.darkPageBackground,
          },
          '& .Mui-focused > fieldset': {
            border: `1px solid ${designTokens.colors.darkContextHigh} !important`,
          },
          '& .MuiOutlinedInput-root:hover > fieldset': {
            borderColor: designTokens.colors.darkContextHigh,
          },
        },
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: designTokens.colors.darkContextHigh,
      },
    },
    MuiStepButton: {
      root: {
        '& .Mui-active .MuiTypography-body1': {
          color: designTokens.colors.white,
        },
        '& .MuiTypography-caption': {
          color: designTokens.colors.darkEmphasisLow,
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: designTokens.colors.coolGray400,
        '&.Mui-checked': {
          color: designTokens.colors.blue400,
        },
      },
    },
  },
  palette: darkPalette,
});

const comfortableStyles: DeprecatedThemeOptions = {
  overrides: {
    MuiFormControl: {
      root: {
        '& .MuiOutlinedInput-root': {
          height: '40px',
        },
      },
    },
  },
};

const condensedStyles: DeprecatedThemeOptions = {
  overrides: {
    MuiFormControl: {
      root: {
        '& .MuiOutlinedInput-root': {
          height: '32px',
        },
        '&.search': {
          height: '40px',
          padding: '2px 0',
        },
      },
    },
    MuiTablePagination: {
      root: {
        '& .MuiTablePagination-toolbar': {
          minHeight: '40px',
          maxHeight: '40px',
        },
        '.MuiTablePagination-actions': {
          color: designTokens.colors.lightEmphasisMedium,
        },
        '& .MuiIconButton-root': {
          width: '35px',
          height: '35px',
        },
      },
    },
    MuiInputBase: {
      root: {
        '& .MuiInputAdornment button': {
          padding: '6px',
        },
        '& .Mui-error .MuiInputAdornment button': {
          right: '6px',
        },
      },
    },
    MuiContainer: {
      root: {
        '& .dataSummary': {
          marginBottom: '20px',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '0 12px',
        '& div': {
          gap: '4px 0',
        },
        '& span': {
          display: 'inline-flex',
          alignItems: 'center',
        },
        '& .MuiButton-contained': {
          padding: '3px',
        },
        '& .MuiButton-outlined': {
          padding: '2px',
        },
        '& .MuiIconButton-root': {
          width: '31px !important',
          height: '31px !important',
        },
        '& .MuiIcon-root': {
          fontSize: '20px',
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: '36px',
        '& .MuiTab-root': {
          minHeight: '36px',
          lineHeight: '1',
          padding: '14px 16px',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&.topZindex': {
          zIndex: '1500',
          position: 'relative',
        },
        '&.MuiTableRow-head': {
          height: '32px',
        },
        '&[tabindex="-1"]': {
          height: '32px',
        },
      },
    },
  },
};

const createDensityTheme = (theme, densityStyles) =>
  createTheme(adaptV4Theme(deepmerge(theme, densityStyles)));

const getDensityTheme = (theme, density) => {
  switch (density) {
    case 'comfortable':
      return createDensityTheme(theme, comfortableStyles);
    case 'condensed': {
      return createDensityTheme(theme, condensedStyles);
    }
    default:
      return createTheme(adaptV4Theme(theme));
  }
};

const supportedLanguages = [
  ...supportedLocales.map((locale) => locale.slice(0, 2)),
] as const;

// eslint-disable-next-line prettier/prettier
export type ZuoraLocales = typeof supportedLocales[number];
// eslint-disable-next-line prettier/prettier
export type ZuoraLanguages = typeof supportedLanguages[number];

export const useLocalizedTheme = (
  theme: DeprecatedThemeOptions,
  userProvidedLanguage?: string,
  userProvidedLocale?: ZuoraLocales,
  density?: ZuoraDensity,
): Theme => {
  const defaultLanguage = 'en';

  const densityTheme = getDensityTheme(theme, density);
  const localeConfig = useLocaleConfig();

  /// Set locale for Pickers
  densityTheme.locale = userProvidedLocale || 'en-US'; // TODO: infer user's language to pick suitable default locale

  // Language
  const isUserProvidedLanguageSupported = supportedLanguages.includes(
    userProvidedLanguage!,
  );
  /// Prefer user-provided language, then default
  const resolvedLanguageKey =
    (isUserProvidedLanguageSupported && userProvidedLanguage) ||
    defaultLanguage;
  /// Set language for string translations in any component
  densityTheme.language = resolvedLanguageKey;

  // Density
  /// Set density in any component
  densityTheme.density = density || 'default';

  // Set locale for MUI
  const localeTheme = createTheme(
    densityTheme,
    localeConfig[getLocaleBasedOnLanguage(resolvedLanguageKey)]
      ?.muiLocaleMeta || enUS,
  );

  return localeTheme;
};
export interface ThemeProviderProps {
  children?: React.ReactNode;
  locale?: ZuoraLocales;
  language?: string;
  density?: ZuoraDensity;
  disableInjectFirst?: boolean;
}

export const lightTheme = createTheme(adaptV4Theme(lightThemeStyles));
export const LightThemeProvider = ({
  locale,
  language,
  children,
  density,
  disableInjectFirst = false,
}: ThemeProviderProps) => {
  const localizedTheme = useLocalizedTheme(
    lightThemeStyles,
    language,
    locale,
    density,
  );

  return (
    <StyledEngineProvider injectFirst={!disableInjectFirst}>
      <ThemeProvider theme={localizedTheme}>
        <SnackbarProvider>
          <CssBaseline />
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const darkTheme = createTheme(adaptV4Theme(darkThemeStyles));
export const DarkThemeProvider = ({
  locale,
  language = 'en',
  children,
  density,
  disableInjectFirst = false,
}: ThemeProviderProps) => {
  const localizedTheme = useLocalizedTheme(
    darkThemeStyles,
    language,
    locale,
    density,
  );

  return (
    <StyledEngineProvider injectFirst={!disableInjectFirst}>
      <ThemeProvider theme={localizedTheme}>
        <SnackbarProvider>
          <CssBaseline />
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const AutoThemeProvider = ({
  children,
  locale,
  language,
  density,
  disableInjectFirst = false,
}: ThemeProviderProps) => {
  const [darkMode] = useLocalStorage('ZDarkMode');
  const ThemeComponent = darkMode ? DarkThemeProvider : LightThemeProvider;

  return (
    <ThemeComponent
      {...{ locale, language, children, density, disableInjectFirst }}
    />
  );
};

import * as React from 'react';
import { Box, CircularProgress, Collapse, IconButton } from '@mui/material';
import { Alert as MUIAlert, AlertTitle as MUIAlertTitle } from '@mui/material';
import { AlertProps as MUIAlertProps } from '@mui/lab';
import { CheckCircle, Close, Info, Warning, Cancel } from '@mui/icons-material';
import styled from '@emotion/styled';
import { transientOptions } from '../utils';

export interface AlertProps
  extends Pick<MUIAlertProps, 'severity' | 'action' | 'variant' | 'icon'> {
  body?: React.ReactNode;
  children?: React.ReactNode;
  dsOnClose?: MUIAlertProps['onClose'];
  e2e?: string;
  header?: React.ReactNode;
  open?: boolean;
  dismissible?: boolean;
  center?: boolean;
  loading?: boolean;
}

const StyledAlert = styled(MUIAlert, transientOptions)<{
  $center: boolean;
}>`
  ${({ $center }) => {
    if ($center) {
      return `
          & .MuiAlert-icon {
            margin-left: auto;
          }
          & .MuiAlert-message {
            margin-right: auto;
          }
          & .MuiAlert-action {
            margin-left: 0;
          }
        `;
    }
    return '';
  }}
`;
const iconMap: MUIAlertProps['iconMapping'] = {
  success: <CheckCircle fontSize="inherit" />,
  info: <Info fontSize="inherit" />,
  warning: <Warning fontSize="inherit" />,
  error: <Cancel fontSize="inherit" />,
};

const Alert = ({
  body,
  children,
  dsOnClose,
  e2e,
  header,
  open,
  severity = 'error',
  action,
  dismissible = false,
  variant = 'standard',
  center = false,
  loading = false,
  icon,
}: AlertProps) => {
  /* Web components progressively render. We need to wait
   * for the open value to animate properly.
   */
  if (open === undefined) {
    return null;
  }

  return (
    <Collapse in={open} {...(e2e && { 'data-e2e': e2e })}>
      <StyledAlert
        iconMapping={iconMap}
        severity={severity}
        icon={loading ? <CircularProgress size={16} color="inherit" /> : icon}
        action={
          <>
            {action && <Box>{action}</Box>}
            {dismissible && (
              <IconButton
                color="inherit"
                size="small"
                aria-label="Close"
                title="Close"
                onClick={dsOnClose}
                {...(e2e && { 'data-e2e': `${e2e}-close` })}
              >
                <Close />
              </IconButton>
            )}
          </>
        }
        variant={variant}
        $center={center}
      >
        {header && (
          <MUIAlertTitle {...(e2e && { 'data-e2e': `${e2e}-header` })}>
            {header}
          </MUIAlertTitle>
        )}
        <span {...(e2e && { 'data-e2e': `${e2e}-body` })}>
          {body}
          {children}
        </span>
      </StyledAlert>
    </Collapse>
  );
};

Alert.defaultProps = {
  severity: 'error',
};

export default Alert;

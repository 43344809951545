import {
  Box,
  IconButtonProps,
  ListItemProps,
  ThemeProvider,
} from '@mui/material';
import * as React from 'react';
import { ButtonProps } from '../Button';
import { IconProps } from '../Icon';
import { Popover, List, ListItem, Divider } from '@mui/material';
import Button from '../Button';
import styled from '@emotion/styled';
import { transientOptions } from '../utils';
import { lightTheme } from '../Theme';

export const ActionsWrapper = styled(Box)`
  display: flex;
  justify-content: right;
  align-items: center;

  &.MUIButton-root {
    white-space: nowrap;
  }
`;

const StyledActionPoppover = styled('div', transientOptions)<
  ActionsListProps & {
    $isRowActions?: ActionsListProps['isRowActions'];
  }
>`
  ${({ $isRowActions }) =>
    $isRowActions
      ? `
          && {
            min-width: 120px;
          }
        `
      : ''}
`;
const StyledPopoverContent = styled.div`
  min-width: 160px;
  padding: 8px 0;
`;

const StyledListItem = styled(ListItem)`
  height: 40px;
  cursor: pointer;
  text-transform: capitalize;
`;

export interface ActionListItemChild {
  label: string;
  onClick?: IconButtonProps['onClick'] | ListItemProps<any>['onClick'];
  disabled?: boolean;
  id?: string;
  href?: string;
}

export interface ActionListItem {
  id?: string;
  e2e?: string;
  href?: string;
  label?: string;
  tooltip?: string;
  icon?: IconProps['body'];
  onClick?: (
    eventData: React.MouseEvent<HTMLButtonElement> | { row?: any },
  ) => void;
  listChildren?: ActionListItemChild[];
  variant?: ButtonProps['variant'];
  disabled?: ButtonProps['disabled'];
  divide?: 'left' | 'right';
  body?: React.ReactNode;
}

export type ActionsListProps = {
  e2e?: string;
  actions?: ActionListItem[];
  gap?: number | string;
  context?: any;
  isRowActions?: boolean;
};

const ActionsDivider = (
  <Divider orientation="vertical" variant="middle" flexItem />
);

export const ActionsList: React.FC<ActionsListProps> = ({
  actions,
  e2e,
  gap = 1,
  context = {},
  isRowActions = false,
}: ActionsListProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'actions-popover' : undefined;

  const identifier = (action) => action.label || action.icon || action.id;

  return (
    <ActionsWrapper
      {...(e2e && {
        'data-e2e': `${e2e}-actions-wrapper`,
      })}
      className="ds-row-actions"
      sx={{ gap }}
    >
      {actions?.map((action) => (
        <React.Fragment key={`action-${identifier(action)}`}>
          {action.divide === 'left' && ActionsDivider}
          <Button
            {...(e2e && {
              e2e: `${e2e}-action-${identifier(action)}`,
            })}
            a11yLabel={action.label}
            variant={action.variant}
            disabled={action.disabled}
            icon={action.icon}
            body={action.label}
            tooltip={action.tooltip}
            href={action.href}
            dsOnClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              action.listChildren && setAnchorEl(event.currentTarget);
              action.onClick?.({ ...event, ...context });
            }}
          />
          <ThemeProvider theme={lightTheme}>
            {action.listChildren && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <StyledPopoverContent>
                  <StyledActionPoppover $isRowActions={isRowActions}>
                    <List disablePadding>
                      {action.listChildren.map((child) => {
                        return (
                          <React.Fragment
                            key={`action-child-${identifier(child)}`}
                          >
                            <StyledListItem
                              {...(!isRowActions && { dense: true })}
                              {...(child.href && { component: 'a' })}
                              {...(child.href && { href: child.href })}
                              {...(child.disabled && {
                                disabled: child.disabled,
                              })}
                              onClick={(event) => {
                                child.onClick?.({ ...event, ...context });
                                handleClose();
                              }}
                            >
                              {child.label}
                            </StyledListItem>
                          </React.Fragment>
                        );
                      })}
                    </List>
                  </StyledActionPoppover>
                </StyledPopoverContent>
              </Popover>
            )}
          </ThemeProvider>
          {action.divide === 'right' && ActionsDivider}
        </React.Fragment>
      ))}
    </ActionsWrapper>
  );
};

export default ActionsList;

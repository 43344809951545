import * as React from 'react';
import {
  IconButtonProps,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MUITextFieldProps,
} from '@mui/material';
import { AllIconsType } from '../index';
import Button from '../Button';
import Icon from '../Icon';
import makeClass from 'classnames';
import Typography from '../Typography';

export interface TextFieldProps
  extends Pick<
    MUITextFieldProps,
    | 'fullWidth'
    | 'value'
    | 'label'
    | 'helperText'
    | 'multiline'
    | 'rows'
    | 'autoFocus'
    | 'autoComplete'
    | 'disabled'
    | 'error'
    | 'name'
    | 'placeholder'
    | 'required'
    | 'type'
    | 'fullWidth'
    | 'inputRef'
    | 'maxRows'
  > {
  /**
   * ARIA Label for end adornment.
   */
  a11yEndAdornmentLabel?: string;
  /**
   * ARIA label. Use if component if not accompanied by a visible label.
   */
  a11yLabel?: string;
  dsOnChange: MUITextFieldProps['onChange'];
  dsOnBlur?: MUITextFieldProps['onBlur'];
  dsOnFocus?: MUITextFieldProps['onFocus'];
  dsOnEndAdornmentClick?: IconButtonProps['onClick'];
  dsOnKeyPress?: MUITextFieldProps['onKeyPress'];
  e2e?: string;
  /** @deprecated */
  e2eLabel?: string;
  endAdornmentIcon?: AllIconsType;
  endAdornmentText?: string;
  maxlength?: number;
  minlength?: number;
  readonly?: boolean;
  startAdornmentIcon?: AllIconsType;
  wrap?: 'hard' | 'soft' | 'off';
  resize?: React.CSSProperties['resize'];
  value: string;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      a11yEndAdornmentLabel,
      a11yLabel,
      autoFocus,
      autoComplete,
      disabled,
      dsOnChange,
      dsOnBlur,
      dsOnFocus,
      dsOnEndAdornmentClick,
      dsOnKeyPress,
      endAdornmentIcon,
      endAdornmentText,
      error,
      e2e,
      e2eLabel,
      fullWidth,
      helperText,
      label,
      maxlength,
      minlength,
      multiline,
      name,
      placeholder,
      readonly,
      required,
      rows,
      startAdornmentIcon,
      type,
      value,
      wrap,
      maxRows,
      resize = 'both',
    },
    ref,
  ) => {
    /* In the design system, all inputs are controlled.
     * Uncontrolled is when value === undefined. If presented
     * with an this invalid state, render nothing.
     */
    if (value === undefined) {
      return null;
    }

    if (type === 'number' && (minlength || minlength === 0 || maxlength)) {
      if (maxlength && parseInt(value, 10) > maxlength) {
        value = maxlength.toString();
      }
      if ((minlength || minlength === 0) && parseInt(value, 10) < minlength) {
        value = minlength.toString();
      }
    }

    const resolvedE2E = e2e || e2eLabel;

    return (
      <MuiTextField
        aria-label={a11yLabel}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        className={makeClass({ ReadOnly: readonly })}
        disabled={disabled}
        error={error}
        aria-readonly={readonly}
        readonly={readonly}
        fullWidth={fullWidth}
        helperText={helperText}
        onKeyPress={dsOnKeyPress}
        inputRef={ref}
        inputProps={{
          'data-e2e': resolvedE2E,
          maxLength: maxlength,
          minLength: minlength,
        }}
        InputProps={{
          sx: {
            '& textarea': {
              resize,
            },
            ...(multiline && {
              '&&': {
                padding: '10px 5px 5px 10px',
                height: 'auto',
              },
            }),
          },
          endAdornment: (() => {
            if (dsOnEndAdornmentClick && endAdornmentIcon) {
              return (
                <InputAdornment position="end">
                  <Button
                    a11yLabel={a11yEndAdornmentLabel}
                    dsOnClick={dsOnEndAdornmentClick}
                    icon={endAdornmentIcon}
                  />
                </InputAdornment>
              );
            }
            if (endAdornmentIcon) {
              return (
                <InputAdornment position="end">
                  <Icon body={endAdornmentIcon} />
                </InputAdornment>
              );
            }
            if (endAdornmentText) {
              return (
                <InputAdornment position="end">
                  <Typography color="textSecondary" body={endAdornmentText} />
                </InputAdornment>
              );
            }
          })(),
          readOnly: readonly,
          startAdornment: (() => {
            if (startAdornmentIcon) {
              return (
                <InputAdornment position="start">
                  <Icon body={startAdornmentIcon} __addMargin />
                </InputAdornment>
              );
            }
          })(),
          // @ts-ignore
          wrap,
        }}
        label={label}
        multiline={multiline}
        name={name}
        variant="outlined"
        onChange={dsOnChange}
        onBlur={dsOnBlur}
        onFocus={dsOnFocus}
        placeholder={placeholder}
        required={required}
        minRows={rows}
        maxRows={maxRows}
        value={value}
        type={type}
      />
    );
  },
);

export default TextField;

import { useTheme } from '@mui/material';
import React from 'react';

export const en = {
  NoRecordsFound: 'No Records Found',
  FilterBar_All: 'All',
  FilterBar_No: 'No',
  FilterBar_Yes: 'Yes',
  FilterBar_DurationDays: '{0} days',
  FilterBar_CustomRange: 'Custom',
  FilterBar_StartDate: 'Start Date',
  FilterBar_EndDate: 'End Date',
  FilterBar_Update: 'Update',
  FilterBar_ExactMatch: 'Exact Match',
  FilterBar_DoesNotMatch: 'Does Not Match',
  FilterBar_LessThan: 'Less Than',
  FilterBar_LessThanOrEqual: 'Less Than Or Equal',
  FilterBar_GreaterThan: 'Greater Than',
  FilterBar_GreaterThanOrEqual: 'Greater Than Or Equal',
  FilterBar_Contains: 'Contains',
  FilterBar_StartsWith: 'Starts With',
  FilterBar_Equals: 'Equals',
  FilterBar_More: 'add new filter',
  FilterBar_Clear: 'clear',
  FilterBar_SearchIndexTooltip:
    'This search box includes the following fields:',
  FilterBar_CustomFields: 'Custom Fields',
  Search: 'Search...',
  SearchPlaceholder: 'Contains text',
  QuickFilterPlaceholder: 'Quick filter',
  SearchStartsWith: 'Starts with text',
  TablePagination_GoToFirstPage: 'Go to first page',
  TablePagination_GoToLastPage: 'Go to last page',
  TablePagination_GoToNextPage: 'Go to next page',
  TablePagination_GoToPreviousPage: 'Go to previous page',
  InvalidDate: '{0} is invalid date',
  TableInvalidDate_Format: 'Date is invalid. Correct format is {0}',
  InvalidRange: 'Invalid date range',
  ExpandSearchExplainer:
    'Clearing filters or toggling the operator from “Starts With” to “Contains” may yield more search results.',
  AllTabFilterOption: 'All',
  MultiOrgCascadingDown:
    'Select the whole branch and include future changes on this branch.',
  MultiOrgDisableCascadingDown: 'Unselect this branch.',
  MultiOrgExcluded: 'Exclude this item from selection.',
  MultiOrgIncluded: 'Include this item in selection.',
  Include: 'Include',
  Exclude: 'Exclude',
  MultiOrgExcludeOrg: 'Exclude {}',
  MultiOrgExcludedCascadingDown:
    'Exclude this branch and any organization added to this branch in future.',
  MultiOrgDisableExcludedCascadingDown:
    'Click to only exclude this organization.',
  ClearSelection: 'Clear Selection',
  NoResults: 'No results found',
  ClearFilters: 'Clear Filters',
  UseGlobalSearch: 'Use Global Search',
  GlobalSearchExplainer:
    'Using Global Search will expand the scope to include more objects.',
  CopyConfirmation: 'Copied to clipboard!',
  RowsPerPageLabel: 'Show',
  QueryTimeoutError: 'Query Timeout Error',
  QueryTimeoutExplainer:
    'The server is taking too long to process the query and is unable to complete your request. Run a faster query by changing the query parameters such as toggling the search operator from “Contains” to “Starts With” and Excluding Custom Fields. If the problem persists, please contact Zuora Support for more information.',
  ChangeQueryParameters: 'Change Query Parameters',
  ResetFilters: 'Reset Filters',
  CustomizeColumns: 'Customize Columns',
  ZuoraSupport: 'Zuora Support',
};

export const ja = {
  NoRecordsFound: '表示するレコードがありません。',
  FilterBar_All: 'すべて',
  FilterBar_No: 'いいえ',
  FilterBar_Yes: 'はい',
  FilterBar_DurationDays: '{0} 日',
  FilterBar_CustomRange: 'カスタム',
  FilterBar_StartDate: '開始日',
  FilterBar_EndDate: '終了日',
  FilterBar_Update: '更新',
  FilterBar_ExactMatch: '完全一致',
  FilterBar_DoesNotMatch: '次と等しくない',
  FilterBar_LessThan: '次未満',
  FilterBar_LessThanOrEqual: '次以下',
  FilterBar_GreaterThan: '次より大きい',
  FilterBar_GreaterThanOrEqual: '次以上',
  FilterBar_Contains: '次の値を含む',
  FilterBar_StartsWith: '次の値で始まる',
  FilterBar_Equals: 'イコール',
  FilterBar_More: '新しいフィルタの追加',
  FilterBar_Clear: 'クリア',
  FilterBar_SearchIndexTooltip:
    'この検索ボックスには次のフィールドが含まれます：',
  FilterBar_CustomFields: 'カスタムフィールド',
  Search: '検索...',
  SearchPlaceholder: '次のテキストを含む：',
  QuickFilterPlaceholder: 'クイックフィルター',
  SearchStartsWith: '次の値で始まる',
  TablePagination_GoToFirstPage: '先頭ページに戻る',
  TablePagination_GoToLastPage: '最終ページに進む',
  TablePagination_GoToNextPage: '次のページに進む',
  TablePagination_GoToPreviousPage: '前のページに戻る',
  InvalidDate: '{0}は無効な日付です',
  TableInvalidDate_Format: '日付が無効です。正しい形式は{0}です',
  InvalidRange: '無効な日付の範囲です',
  ExpandSearchExplainer:
    '演算子を「次で始まる」から「次を含む」に切り替えると、さらに検索結果が得られる場合があります。',
  AllTabFilterOption: 'すべて',
  MultiOrgCascadingDown:
    'ブランチ全体を選択して、このブランチの将来の変更を含めます。',
  MultiOrgDisableCascadingDown: 'このブランチの選択を解除します。',
  MultiOrgExcluded: 'この項目を選択から除外します。',
  MultiOrgIncluded: 'この項目を選択に含めます。',
  Include: '含める',
  Exclude: '除外する',
  MultiOrgExcludeOrg: '除外 {}',
  MultiOrgExcludedCascadingDown:
    'このブランチおよびこのブランチに将来追加される組織を除外します。',
  MultiOrgDisableExcludedCascadingDown:
    'クリックしてこの組織のみを除外します。',
  NoResults: '結果は見つかりませんでした',
  ClearFilters: 'フィルタをクリア',
  UseGlobalSearch: 'Use Global Search',
  GlobalSearchExplainer:
    'Using Global Search will expand the scope to include more objects.',
  CopyConfirmation: 'クリップボードにコピーしました。',
  RowsPerPageLabel: '表示',
  QueryTimeoutError: 'Query Timeout Error',
  QueryTimeoutExplainer:
    'サーバーがクエリを処理するのに時間がかかり、リクエストを完了できません。検索演算子を「以下を含む」から「以下で始まる」に切り替えたり、カスタムフィールドを除外したりするなど、クエリパラメータを変更すると高速なクエリを実行できる場合があります。問題が解決しない場合は、Zuoraサポートに詳細をお問い合わせください。',
  ChangeQueryParameters: 'クエリパラメータの変更',
  ResetFilters: 'フィルタをリセット',
  CustomizeColumns: '列のカスタマイズ',
  ZuoraSupport: 'Zuoraサポート',
};

export const de = {
  NoRecordsFound: 'Keine Einträge für diese Ansicht gefunden.',
  FilterBar_All: 'Alle',
  FilterBar_No: 'Nein',
  FilterBar_Yes: 'Ja',
  FilterBar_DurationDays: '{0} Tage',
  FilterBar_CustomRange: 'Benutzerdefiniert',
  FilterBar_StartDate: 'Startdatum',
  FilterBar_EndDate: 'Enddatum',
  FilterBar_Update: 'Aktualisierung',
  FilterBar_ExactMatch: 'Exakt übereinstimmend',
  FilterBar_DoesNotMatch: 'ungleich',
  FilterBar_LessThan: 'kleiner als',
  FilterBar_LessThanOrEqual: 'kleiner oder gleich',
  FilterBar_GreaterThan: 'größer als',
  FilterBar_GreaterThanOrEqual: 'größer oder gleich',
  FilterBar_Contains: 'Enthält',
  FilterBar_StartsWith: 'Beginnend mit',
  FilterBar_Equals: 'Gleich',
  FilterBar_More: 'Neuen Filter hinzufügen',
  FilterBar_Clear: 'löschen',
  FilterBar_SearchIndexTooltip: 'Dieses Suchfeld enthält die folgenden Felder:',
  FilterBar_CustomFields: 'Benutzerdefinierte Felder',
  Search: 'Suchen...',
  SearchPlaceholder: 'Enthält Text',
  QuickFilterPlaceholder: 'Schnellfilter',
  SearchStartsWith: 'Beginnend mit Text',
  TablePagination_GoToFirstPage: 'Zur ersten Seite',
  TablePagination_GoToLastPage: 'Zur letzten Seite',
  TablePagination_GoToNextPage: 'Zur nächsten Seite',
  TablePagination_GoToPreviousPage: 'Zur vorherigen Seite',
  InvalidDate: '{0} ist ein ungültiges Datum',
  TableInvalidDate_Format:
    'Das Datum ist unzulässig. Das richtige Format ist {0}',
  InvalidRange: 'Ungültiger Datenbereich',
  ExpandSearchExplainer:
    'Wenn Sie den Operator von „Beginnt mit“ auf „Enthält“ umstellen, erhalten Sie möglicherweise mehr Suchergebnisse.',
  AllTabFilterOption: 'Alle',
  MultiOrgCascadingDown:
    'Den gesamten Zweig auswählen und zukünftige Änderungen an diesem Zweig einbeziehen.',
  MultiOrgDisableCascadingDown: 'Diesen Zweig abwählen.',
  MultiOrgExcluded: 'Dieses Element von der Auswahl ausschließen.',
  MultiOrgIncluded: 'Dieses Element in die Auswahl einbeziehen.',
  Include: 'Einschließen',
  Exclude: 'Ausschließen',
  MultiOrgExcludeOrg: 'Ausschließen {}',
  MultiOrgExcludedCascadingDown:
    'Diesen Zweig und alle Organisationen, die diesem Zweig in Zukunft hinzugefügt werden, ausschließen.',
  MultiOrgDisableExcludedCascadingDown:
    'Klicken Sie, um nur diese Organisation auszuschließen.',
  NoResults: 'Keine Ergebnisse gefunden',
  ClearFilters: 'Filter löschen',
  UseGlobalSearch: 'Use Global Search',
  GlobalSearchExplainer:
    'Using Global Search will expand the scope to include more objects.',
  CopyConfirmation: 'In die Zwischenablage kopiert!',
  RowsPerPageLabel: 'Anzeigen',
  QueryTimeoutError: 'Query Timeout Error',
  QueryTimeoutExplainer:
    'Zeitüberschreitung bei der Bearbeitung Ihrer Abfrage auf dem Server, weshalb sie nicht erfolgreich abgeschlossen werden konnte. Ändern Sie für eine schnellere Abfrage die Abfrageparameter, indem Sie Suchoperatoren von „Enthält“ auf beispielsweise „Beginnt mit“ ändern und benutzerdefinierte Felder für die Abfrage ausschließen. Wenn das Problem weiterhin bestehen sollte, wenden Sie sich bitte für weitere Informationen an den Zuora-Support.',
  ChangeQueryParameters: 'Abfrageparameter ändern',
  ResetFilters: 'Filter zurücksetzen',
  CustomizeColumns: 'Spalten anpassen',
  ZuoraSupport: 'Zuora-Support',
};

export const fr = {
  NoRecordsFound: 'Aucune fiche trouvée pour cette vue.',
  FilterBar_All: 'Tout',
  FilterBar_No: 'Non',
  FilterBar_Yes: 'Oui',
  FilterBar_DurationDays: '{0} jours',
  FilterBar_CustomRange: 'Personnalisée',
  FilterBar_StartDate: 'Date de début',
  FilterBar_EndDate: 'Date de fin',
  FilterBar_Update: 'Mettre à jour',
  FilterBar_ExactMatch: 'Correspondance exacte',
  FilterBar_DoesNotMatch: 'n’est pas égal à',
  FilterBar_LessThan: 'inférieur à',
  FilterBar_LessThanOrEqual: 'inférieur ou égal à',
  FilterBar_GreaterThan: 'supérieur à',
  FilterBar_GreaterThanOrEqual: 'supérieur ou égal à',
  FilterBar_Contains: 'Contient',
  FilterBar_StartsWith: 'Commence par',
  FilterBar_Equals: 'Est égal à',
  FilterBar_More: 'ajouter un nouveau filtre',
  FilterBar_Clear: 'Effacer',
  FilterBar_SearchIndexTooltip:
    'Cette boîte de recherche comprend les champs suivants:',
  FilterBar_CustomFields: 'Champs Personnalisés',
  Search: 'Rechercher...',
  SearchPlaceholder: 'Contient du texte',
  QuickFilterPlaceholder: 'Filtre rapide',
  SearchStartsWith: 'Commence par du texte',
  TablePagination_GoToFirstPage: 'Première page',
  TablePagination_GoToLastPage: 'Dernière page',
  TablePagination_GoToNextPage: 'Page suivante',
  TablePagination_GoToPreviousPage: 'Page précédente',
  InvalidDate: '{0} est une date non valide',
  TableInvalidDate_Format: 'Date non valide. Le format correct est {0}',
  InvalidRange: 'Plage de dates non valide',
  ExpandSearchExplainer:
    'Basculer l’opérateur ’Commence par’ sur ’Contient’ peut produire davantage de résultats de recherche.',
  AllTabFilterOption: 'Tout',
  MultiOrgCascadingDown:
    'Sélectionner la section entière et inclure les changements futurs sur cette section.',
  MultiOrgDisableCascadingDown: 'Désélectionner cette section.',
  MultiOrgExcluded: 'Exclure cet élément de la sélection.',
  MultiOrgIncluded: 'Inclure cet élément dans la sélection.',
  Include: 'Inclure',
  Exclude: 'Exclure',
  MultiOrgExcludeOrg: 'Exclure {}',
  MultiOrgExcludedCascadingDown:
    'Exclure cette section et toute organisation ajoutée à cette section à l’avenir.',
  MultiOrgDisableExcludedCascadingDown:
    'Cliquer pour exclure cette organisation uniquement.',
  NoResults: 'Aucun résultat trouvé',
  ClearFilters: 'Effacer les filtres',
  UseGlobalSearch: 'Use Global Search',
  GlobalSearchExplainer:
    'Using Global Search will expand the scope to include more objects.',
  CopyConfirmation: 'Copié dans le presse-papier.',
  RowsPerPageLabel: 'Afficher',
  QueryTimeoutError: 'Query Timeout Error',
  QueryTimeoutExplainer:
    'Le serveur prend trop de temps pour traiter la requête et ne peut pas terminer votre demande. Exécutez une requête plus rapide en modifiant les paramètres de la requête, comme basculer l\'opérateur de recherche de "Contient" à "Commence par" et exclure les champs personnalisés. Si le problème persiste, veuillez contacter l\'assistance Zuora pour plus d\'informations.',
  ChangeQueryParameters: 'Modifier les paramètres de requête',
  ResetFilters: 'Réinitialiser les filtres',
  CustomizeColumns: 'Personnaliser les colonnes',
  ZuoraSupport: 'Assistance Zuora',
};

export const es = {
  NoRecordsFound: 'No se encontraron registros para esta vista.',
  FilterBar_All: 'Todo',
  FilterBar_No: 'No',
  FilterBar_Yes: 'Sí',
  FilterBar_DurationDays: '{0} días',
  FilterBar_CustomRange: 'Personalizar',
  FilterBar_StartDate: 'Fecha de inicio',
  FilterBar_EndDate: 'Fecha de finalización',
  FilterBar_Update: 'Actualizar',
  FilterBar_ExactMatch: 'Coincidencia exacta',
  FilterBar_DoesNotMatch: 'no es igual a',
  FilterBar_LessThan: 'inferior a',
  FilterBar_LessThanOrEqual: 'inferior o igual',
  FilterBar_GreaterThan: 'superior a',
  FilterBar_GreaterThanOrEqual: 'superior o igual',
  FilterBar_Contains: 'Contiene',
  FilterBar_StartsWith: 'Empieza con',
  FilterBar_Equals: 'Es igual',
  FilterBar_More: 'añadir nuevo filtro',
  FilterBar_Clear: 'borrar',
  FilterBar_SearchIndexTooltip:
    'Este cuadro de búsqueda incluye los siguientes campos:',
  FilterBar_CustomFields: 'Campos Personalizados',
  Search: 'Buscar...',
  SearchPlaceholder: 'Contiene texto',
  QuickFilterPlaceholder: 'Filtro rápido',
  SearchStartsWith: 'Empieza con texto',
  TablePagination_GoToFirstPage: 'Ir a la primera página',
  TablePagination_GoToLastPage: 'Ir a la última página',
  TablePagination_GoToNextPage: 'Ir a la página siguiente',
  TablePagination_GoToPreviousPage: 'Ir a la página anterior',
  InvalidDate: '{0} no es una fecha válida',
  TableInvalidDate_Format: 'La fecha no es válida. El formato correcto es {0}',
  InvalidRange: 'Intervalo de fecha no válido',
  ExpandSearchExplainer:
    'Si el operador cambia de ‘Empieza con‘ a ‘Contiene‘ es posible que se obtengan más resultados en la búsqueda.',
  AllTabFilterOption: 'Todas',
  MultiOrgCascadingDown:
    'Seleccionar toda la sucursal e incluir futuros cambios en esta sucursal.',
  MultiOrgDisableCascadingDown: 'Deseleccionar esta sucursal.',
  MultiOrgExcluded: 'Excluir este elemento de la selección.',
  MultiOrgIncluded: 'Incluir este elemento en la selección.',
  Include: 'Incluir',
  Exclude: 'Excluir',
  MultiOrgExcludeOrg: 'Excluir {}',
  MultiOrgExcludedCascadingDown:
    'Excluir esta sucursal y cualquier organización agregada a esta sucursal en el futuro.',
  MultiOrgDisableExcludedCascadingDown:
    'Haga clic para excluir solo esta organización.',
  NoResults: 'No se encontraron resultados',
  ClearFilters: 'Borrar filtros',
  UseGlobalSearch: 'Use Global Search',
  GlobalSearchExplainer:
    'Using Global Search will expand the scope to include more objects.',
  CopyConfirmation: 'Copiado al portapapeles',
  RowsPerPageLabel: 'Mostrar',
  QueryTimeoutError: 'Query Timeout Error',
  QueryTimeoutExplainer:
    'El servidor está tardando demasiado tiempo en procesar la consulta y no puede tramitar su solicitud. Ejecute una consulta más rápida cambiando los parámetros de la consulta, por ejemplo, cambiando el operador de búsqueda de "contiene" a "Empieza con" y excluyendo campos personalizados. Si el problema continúa, póngase en contacto con la Asistencia de Zuora para obtener más información.',
  ChangeQueryParameters: 'Cambiar parámetros de consulta',
  ResetFilters: 'Restablecer filtros',
  CustomizeColumns: 'Personalizar columnas',
  ZuoraSupport: 'Asistencia de Zuora',
};

const translationMap = {
  en,
  ja,
  de,
  fr,
  es,
};

export const useTranslate = () => {
  const theme = useTheme();
  const langKey = theme.language || 'en';
  return {
    translate: (translationKey: string, replacements?: string[]) => {
      let result = translationMap[langKey]?.[translationKey] || translationKey;
      if (replacements) {
        replacements.forEach(
          (replacement, id) =>
            (result = result.replace(`{${id}}`, replacement)),
        );
      }
      return result;
    },
    translateToNode: (translationKey: string, replacements?: any[]) => {
      const key = translationMap[langKey]?.[translationKey] || translationKey;
      const result = key
        .split('{}')
        .reduce(
          (prev, curr, index) => [
            ...prev,
            curr,
            ...(replacements?.[index] ? [replacements[index]] : []),
          ],
          [],
        );
      return (
        <React.Fragment>
          {result.map((item, index) => (
            <React.Fragment key={index}>{item}</React.Fragment>
          ))}
        </React.Fragment>
      );
    },
  };
};

import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { Grow } from '@mui/material';
import Alert from '../Alert';
import { ToastProps } from '.';

const MultipleToast: React.FC<ToastProps> = ({
  e2e,
  severity,
  message,
  header,
  keyRender,
  closeable = true,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  duration = null,
  variant = 'outlined',
  loading = false,
  action,
}: ToastProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!message) {
      return;
    }
    enqueueSnackbar('', {
      content: (
        <Box {...(e2e && { 'data-e2e': e2e })}>
          <Alert
            {...(e2e && { e2e: `${e2e}-alert` })}
            severity={severity || 'info'}
            dsOnClose={() => closeSnackbar(keyRender)}
            open
            loading={loading}
            variant={variant}
            dismissible={closeable}
            body={message}
            header={header}
            action={action}
          />
        </Box>
      ),
      anchorOrigin,
      autoHideDuration: duration,
      key: keyRender,
      TransitionComponent: Grow as any,
    });
  }, [
    e2e,
    severity,
    message,
    header,
    keyRender,
    closeable,
    anchorOrigin,
    duration,
    variant,
    loading,
    action,
    enqueueSnackbar,
    closeSnackbar,
  ]);

  return <></>;
};

export default MultipleToast;

import * as React from 'react';
import styled from '@emotion/styled';
import { transientOptions } from '../utils';
import {
  Tooltip,
  Typography as MuiTypography,
  TypographyProps as MUITypographyProps,
} from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis';
import TitleBar from '../TitleBar';
import { StandardCSSProperties } from '@mui/system';

export interface TypographyProps
  extends Pick<
    MUITypographyProps<'span', { component?: React.ElementType<any> }>,
    'className' | 'color' | 'display' | 'gutterBottom' | 'noWrap' | 'sx'
  > {
  body?: React.ReactNode;
  children?: React.ReactNode;
  component?: React.ElementType<any>;
  e2e?: string;
  tooltip?: string;
  tooltipOpen?: boolean;
  /** If true, tooltip is only shown on hover for truncated text
   * @default false
   */
  tooltipOnlyWhenTruncated?: boolean;
  variant?: MUITypographyProps<'span'>['variant'];
  truncated?: boolean;
  maxLine?: number;
  titleBar?: boolean;
}

const StyledMuiTypography = styled(MuiTypography, transientOptions)<
  MUITypographyProps & {
    $variant?: TypographyProps['variant'];
    $titleBar?: TypographyProps['titleBar'];
  }
>`
  ${({ $variant, color, sx }) =>
    $variant === 'overline' && !color && !(sx as StandardCSSProperties)?.color
      ? `
    && {
      color: inherit;
    }
  `
      : ''}

  ${({ $titleBar }) =>
    $titleBar
      ? `
          && {
            display: flex;
          }
        `
      : ''}
`;

const Typography: React.FC<TypographyProps> = ({
  body,
  children,
  color,
  component,
  display,
  e2e,
  gutterBottom,
  tooltip = '',
  tooltipOpen,
  variant,
  className,
  noWrap = false,
  truncated = false,
  tooltipOnlyWhenTruncated = false,
  maxLine = 2,
  titleBar = false,
  sx,
}: TypographyProps) => {
  const [clamped, setClamped] = React.useState<boolean>(false);

  return (
    <Tooltip
      arrow
      title={tooltip ? tooltip : truncated ? <>{body || children}</> : false}
      {...(tooltip && { open: tooltipOpen })}
      disableHoverListener={
        (tooltipOnlyWhenTruncated && !clamped) || (!tooltip && !clamped)
      }
      disableTouchListener={
        (tooltipOnlyWhenTruncated && !clamped) || (!tooltip && !clamped)
      }
      PopperProps={{
        ...(e2e && { 'data-e2e': `${e2e}-tooltip` }),
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -8],
            },
          },
        ],
      }}
    >
      <StyledMuiTypography
        color={color}
        display={display}
        $variant={variant}
        gutterBottom={gutterBottom}
        className={className}
        noWrap={noWrap}
        $titleBar={titleBar}
        {...(component && { component })}
        {...(truncated && { component: 'div' })}
        {...(e2e && { 'data-e2e': e2e })}
        variant={variant}
        sx={sx}
        ref={(ref) => {
          if (tooltipOnlyWhenTruncated && !truncated) {
            setClamped(!!ref && ref.offsetWidth < ref.scrollWidth);
          }
        }}
      >
        {titleBar && <TitleBar />}
        <span {...(e2e && { 'data-e2e': `${e2e}-body` })}>
          {truncated ? (
            <LinesEllipsis
              text={body || children}
              maxLine={maxLine}
              onReflow={(state) => setClamped(state.clamped)}
            />
          ) : (
            <>
              {body}
              {children}
            </>
          )}
        </span>
      </StyledMuiTypography>
    </Tooltip>
  );
};

export default Typography;

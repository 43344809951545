import * as React from 'react';
import { designTokens } from '../Theme.design-tokens';
import Typography from '../Typography';
import Logo from '../Logo';
import { darkTheme } from '..';
import { ActionsList, ActionListItem } from '../ActionsList';
import { Divider, Grid, ThemeProvider } from '@mui/material';
import { Box } from '@mui/material';
import Chip, { ChipProps } from '../Chip';

/* NOTE: This variable is used by nav-v2 to control the slotting of this component.
   https://git.zias.io/platform-ui/nav-v2/pull/245
*/
export const BUILDER_HEADER_ID = 'zds-builder-header';
const BUILDER_HEADER_HEIGHT = '55px';

type HeaderActionType = ActionListItem;

export interface BuilderHeaderProps {
  closeAction?: HeaderActionType;
  actions?: HeaderActionType[];
  title: string;
  description?: string | React.ReactNode;
  logo?: React.ReactNode;
  position?: React.CSSProperties['position'];
  href?: string;
  e2e?: string;
  leftSlot?: React.ReactNode;
  centerSlot?: React.ReactNode;
  /** This slots directly next to the "Zuora" logo to the left of the "title" and "description" */
  appName?: string;
  statusChip?: ChipProps;
}

const BuilderHeader: React.FC<BuilderHeaderProps> = ({
  closeAction,
  actions,
  title,
  description,
  logo,
  position = 'fixed',
  href = '/platform/webapp',
  e2e,
  leftSlot,
  centerSlot,
  appName,
  statusChip,
}: BuilderHeaderProps) => {
  const AppNameSection = () => {
    if (!appName) {
      return <></>;
    }

    return (
      <>
        <Typography
          color={designTokens.colors.darkEmphasisMedium}
          body={appName}
          variant="subtitle2"
        />
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: designTokens.colors.darkStandartInputLine }}
        />
      </>
    );
  };

  return (
    <Box
      id={BUILDER_HEADER_ID}
      sx={{
        position: position,
        zIndex: (theme) => theme.zIndex.modal - 1,
        backgroundColor: designTokens.colors.blueNav,
        minHeight: `${BUILDER_HEADER_HEIGHT} !important`,
        width: '100%',
      }}
    >
      <ThemeProvider theme={darkTheme}>
        <Grid container spacing={3} whiteSpace="nowrap" alignItems="center">
          <Grid item xs zeroMinWidth>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                height: BUILDER_HEADER_HEIGHT,
                paddingLeft: 1,
              }}
            >
              {closeAction && <ActionsList e2e={e2e} actions={[closeAction]} />}

              <a href={href} style={{ display: 'flex' }}>
                {logo ?? <Logo />}
              </a>

              <AppNameSection />
              <Box
                sx={{
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {title && (
                    <Box sx={{ width: '100%' }}>
                      <Typography
                        variant="subtitle2"
                        color={designTokens.colors.darkEmphasisHigh}
                        noWrap
                        body={title}
                      />
                    </Box>
                  )}
                  {leftSlot}
                </Box>
                {description && (
                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <Typography
                      variant="caption"
                      color={designTokens.colors.darkEmphasisHigh}
                      noWrap
                      body={description}
                    />
                  </Box>
                )}
              </Box>
              {statusChip && <Chip {...statusChip} />}
            </Box>
          </Grid>

          {!!centerSlot && (
            <Grid item xs="auto">
              {centerSlot}
            </Grid>
          )}

          {!!actions && (
            <Grid item xs sx={{ paddingRight: 2 }}>
              <ActionsList e2e={e2e} actions={actions} gap={1.5} />
            </Grid>
          )}
        </Grid>
      </ThemeProvider>
    </Box>
  );
};

export default BuilderHeader;
